export const TBM_INFO_DATA = {
  operationDetailList: {
    text: '작업내용을',
    value: 'explanation',
  },
  safetyMeasureList: {
    text: '위험요소를',
    value: 'safetyMeasureName',
  },
  safetyRuleList: {
    text: '안전수칙을',
    value: 'script',
  },
  emergencyProcedureList: {
    text: '비상 시 대처요령을',
    value: 'value',
  },
};
