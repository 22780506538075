import { useInfiniteQuery } from '@tanstack/react-query';

import axiosClient from 'libs/axios/axios';
import { snakeToCamel } from 'util/snakeToCamel';

interface IGetRecentParicipantListParams {
  pageParam: number;
  pageSize?: number;
  keyword?: string;
}

const getRecentParticipantList = async (values: IGetRecentParicipantListParams) => {
  const { pageParam, pageSize = 10, keyword = '' } = values;

  const { data } = await axiosClient.get(
    `/tbm/participant-set?page_num=${pageParam}&page_size=${pageSize}&keyword=${keyword}`,
  );

  return {
    result: snakeToCamel(data.data),
    nextPage: pageParam + 1,
    isLast: !!data.next,
  };
};

const useGetRecentParticipantListQuery = (keyword?: string) =>
  useInfiniteQuery(
    ['participant', 'recentList', keyword],
    ({ pageParam = 1 }) => getRecentParticipantList({ pageParam, keyword }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.isLast) return lastPage.nextPage;
        return undefined;
      },
      onError: (error) => {
        console.error('get recent participant list error', error);
      },
    },
  );

export default useGetRecentParticipantListQuery;
