import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { snakeToCamel } from 'util/snakeToCamel';

export type VoiceMark = {
  embedString: string;
  qrcodePath: string;
  voicePath: string;
};

const getVoiceMarkQR = async (formData: FormData): Promise<VoiceMark> => {
  try {
    const { data } = await axios.postForm(`${process.env.REACT_APP_VOICEMARK_SERVER}/create_with_key`, formData, {
      headers: {
        Accept: 'application/json, text/plain, /',
      },
    });

    return snakeToCamel(data.voicemark);
  } catch (e) {
    console.error('post error: ', e);
  }
};

const useVoiceMarkQuery = () => useMutation(['tbm', 'save'], (formData: FormData) => getVoiceMarkQR(formData));

export default useVoiceMarkQuery;
