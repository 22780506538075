import { AxiosError } from 'axios';
import axiosClient from 'libs/axios/axios';
import { useQuery } from '@tanstack/react-query';

import { ITemplate } from 'types/tbm';

const fetchTBMTemplate = async () => {
  const { data } = await axiosClient.get('/tbm/template');
  return data;
};

export const useGetTemplateQuery = () =>
  useQuery<ITemplate[], AxiosError>(['CreateTBM', 'template'], () => fetchTBMTemplate(), {
    onError: (error) => {
      console.error('TBM template error:', error);
    },
  });
