import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import useIntersection from 'hooks/useInterSect';
import useDebounce from 'hooks/useDebounce';

import { flex } from 'styles/flex';
import { font } from 'styles/fonts';
import { IRecentParticipant } from 'types/tbmManagement';
import { useSetRecoilState } from 'recoil';
import { tbmSearchKeywordState } from 'pages/recoil/TbmManagement';

interface IRecentParticipantListProps {
  query: any;
  target: IRecentParticipant;
  handleItemClick: Function;
}

function RecentParticipantList({ query, target, handleItemClick }: IRecentParticipantListProps) {
  const setSearchKeyword = useSetRecoilState(tbmSearchKeywordState);
  const [inputValue, setInputValue] = useState('');
  const debounceInputValue = useDebounce({ value: inputValue });

  const { isLoading, isFetching, hasNextPage, fetchNextPage } = query;

  const data = useMemo<IRecentParticipant[]>(
    () => query.data?.pages.flatMap(({ result }: { result: IRecentParticipant[] }) => result) || [],
    [query.data],
  );

  const ref = useIntersection(async (entry, observer) => {
    observer.unobserve(entry.target);
    if (hasNextPage && !isFetching) {
      fetchNextPage();
    }
  });

  const handleSearchKeyword = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    setSearchKeyword((prev) => ({ ...prev, recentList: debounceInputValue }));
    return () => setSearchKeyword((prev) => ({ ...prev, recentList: '' }));
  }, [debounceInputValue]);

  return (
    <>
      <Input>
        <InputField placeholder="검색해주세요." onChange={handleSearchKeyword} />
      </Input>
      <Contents>
        {isLoading ? (
          <span>Loading...</span>
        ) : (
          <InfiniteScrollContainer>
            <GroupList>
              {data?.map((recentData, idx) => (
                <Group
                  key={`group-${idx}`}
                  isActive={target.tbmId === recentData.tbmId}
                  onClick={() => handleItemClick(recentData)}
                >
                  {recentData.date}
                  <br />
                  {recentData.participant.map((el) => el.name).join(',')}
                </Group>
              ))}
            </GroupList>
            <InfiniteScrollTarget ref={ref} />
          </InfiniteScrollContainer>
        )}
      </Contents>
    </>
  );
}

export default RecentParticipantList;

interface IActive {
  isActive: boolean;
}

const Input = styled.div`
  background: #ffffff;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  height: 42px;
  margin-bottom: 24px;
  padding: 12px;
  position: relative;
`;

const InputField = styled.input`
  border: none;
  ${font(12, 400, 18)}
  width: 100%;
  height: 100%;
  ::placeholder {
    color: #999999;
  }
`;

const Contents = styled.div`
  height: 311px;
`;

const GroupList = styled.div`
  ${flex('', '', 'column')}
  width: 100%;
  height: fit-content;
  gap: 10px;
`;

const Group = styled.div<IActive>`
  ${font(12, 400, 18)}
  ${flex('initial', 'flex-start', 'column')};
  padding: 9px 12px;
  background: ${({ isActive }) => (isActive ? '#aaecff' : '#DDDDDD')};
  border-radius: 4px;
  color: #000000;
`;

const InfiniteScrollContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

const InfiniteScrollTarget = styled.div`
  height: 1px;
`;
