import { atom } from 'recoil';
import { ITbmInfoType } from 'types/tbmManagement';
import { ITBMSearchKeywordType } from 'types/tbm';

export const tbmInfoState = atom<ITbmInfoType>({
  key: 'tbmInfoState',
  default: {
    id: 0,
    date: '',
    time: '00:00',
    author: '',
    authorId: 0,
    location: '',
    notice: '',
    operationType: 0,
    operationDetailList: [],
    operationName: '',
    safetyMeasureList: [],
    safetyRuleList: [],
    participantList: [],
    emergencyProcedureList: [],
    attendeeList: [],
  },
});

export const tbmSearchKeywordState = atom<ITBMSearchKeywordType>({
  key: 'tbmSearchKeywordState',
  default: {
    operationDetailList: '',
    safetyMeasureList: '',
    safetyRuleList: '',
    emergencyProcedureList: '',
    participantList: '',
    recentList: '',
  },
});
