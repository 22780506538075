import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';

import { tbmInfoState } from 'pages/recoil/TbmManagement';
import { useGetManagerQuery } from './queries/useGetManagerQuery';

import useIntersection from 'hooks/useInterSect';

import { flex } from 'styles/flex';
import { font } from 'styles/fonts';

import { IManager } from 'types/tbmManagement';

interface IManagerSelectorProps {
  onClose: Function;
}

export default function ManagerSelector({ onClose }: IManagerSelectorProps) {
  const [tbmInfo, setTbmInfo] = useRecoilState(tbmInfoState);
  const { authorId, author } = tbmInfo;

  const [targetItem, setTargetItem] = useState({
    targetId: authorId,
    targetName: author,
  });
  const [inputKeyword, setInputKeyword] = useState('');
  const { targetId, targetName } = targetItem;

  const { data: querydata, hasNextPage, isFetching, fetchNextPage } = useGetManagerQuery(inputKeyword);

  const displayedList = useMemo(
    () => querydata?.pages.flatMap(({ result }: { result: IManager[] }) => result) || [],
    [querydata],
  );

  const handleItemClick = (id: number, value: string) => {
    setTargetItem((prev) => ({ ...prev, targetId: id, targetName: value }));
  };

  const handleConfirmBtnClick = () => {
    setTbmInfo((prev) => ({ ...prev, authorId: targetId, author: targetName }));
    onClose();
  };

  const handleKeywordInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setInputKeyword(value);
  };

  const ref = useIntersection(async (entry, observer) => {
    observer.unobserve(entry.target);
    if (hasNextPage && !isFetching) {
      fetchNextPage();
    }
  });

  return (
    <Container>
      <Input>
        <InputField placeholder="담당자를 검색해주세요." onChange={handleKeywordInput} />
      </Input>
      <InfiniteScrollContainer>
        <ItemBox>
          {displayedList.map((manager: IManager, idx: number) => {
            const { id, name, duty } = manager;
            return (
              <Item key={`item-${idx}`} isActive={targetId === id} onClick={() => handleItemClick(id, name)}>
                {name}
                <div>{duty}</div>
              </Item>
            );
          })}
        </ItemBox>
        <InfiniteScrollTarget ref={ref} />
      </InfiniteScrollContainer>
      <BtnWrap>
        <ConfirmBtn onClick={handleConfirmBtnClick}>확인</ConfirmBtn>
      </BtnWrap>
    </Container>
  );
}

const Container = styled.div`
  ${flex('', '', 'column')}
`;

const Input = styled.div`
  background: #ffffff;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  height: 42px;
  margin: 30px 0 24px 0;
  padding: 10px;
  position: relative;
`;

const InputField = styled.textarea`
  border: none;
  ${font(12, 400, 18)}
  width: 100%;
  height: 100%;
  word-wrap: break-word;
  word-break: break-word;
  font-family: NanumSquare;

  ::placeholder {
    color: #999999;
    ${font(12, 400, 18)}
  }
`;

const InfiniteScrollContainer = styled.div`
  height: 202px;
  margin-bottom: 30px;
  overflow-y: auto;
`;

const InfiniteScrollTarget = styled.div`
  height: 1px;
`;

const ItemBox = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
  ::-webkit-scrollbar {
    display: none;
    width: 0;
  }
  height: fit-content;
`;

const Item = styled.div<{ isActive?: boolean }>`
  background: ${({ isActive }) => (isActive ? '#aaecff' : '#DDDDDD')};
  border-radius: 4px;
  ${font(12, 400, 14)};
  ${flex('space-between', 'center')};
  color: #000000;
  height: 32px;
  padding: 0 12px;
  width: 100%;
`;

const BtnWrap = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;
`;

const ConfirmBtn = styled.button`
  ${font(18, 500, 17)}
  ${flex('center', 'center')}
  height: 52px;
  flex: 1;
  border-radius: 5px;
  text-align: center;
  background: #15aedb;
  color: #ffffff;
`;
