import { useMutation, useQueryClient } from '@tanstack/react-query';
import axiosClient from 'libs/axios/axios';

const fetchDeleteOperationType = async (id: number) => {
  await axiosClient.delete(`/operation/${id}`);
};

export const useDeleteOperationTypeQuery = () => {
  const queryClient = useQueryClient();

  return useMutation((id: number) => fetchDeleteOperationType(id), {
    onSuccess: () => queryClient.invalidateQueries(['operation', 'type']),
    onError: (error) => {
      console.error('delete OperationType error', error);
    },
  });
};
