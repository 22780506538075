import { useMutation, useQueryClient } from '@tanstack/react-query';
import axiosClient from 'libs/axios/axios';

const fetchDeleteTemplate = async (id: number) => {
  await axiosClient.delete(`/tbm/template/${id}`);
};

export const useDeleteTemplateQuery = () => {
  const queryClient = useQueryClient();

  return useMutation((id: number) => fetchDeleteTemplate(id), {
    onSuccess: () => queryClient.invalidateQueries(['CreateTBM', 'template']),
    onError: (error) => {
      console.error('delete template error', error);
    },
  });
};
