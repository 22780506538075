import { useMutation, useQueryClient } from '@tanstack/react-query';
import axiosClient from 'libs/axios/axios';

const addOperationDetail = async (operationDetail: string) => {
  const { data } = await axiosClient.post('/operation/detail', {
    explanation: operationDetail,
  });

  return data;
};

const useAddOperationDetailQuery = () => {
  const queryClient = useQueryClient();

  return useMutation(['operationDetail', 'add'], addOperationDetail, {
    onSuccess: () => {
      queryClient.invalidateQueries(['operationDetail', 'list']);
    },
    onError: (error) => {
      alert('추가 실패. 다시 한 번 시도해주세요');
      console.error('add operation detail error', error);
    },
  });
};

export default useAddOperationDetailQuery;
