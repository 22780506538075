import { useInfiniteQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import axiosClient from 'libs/axios/axios';

import { tbmInfoState } from 'pages/recoil/TbmManagement';
import { snakeToCamel } from 'util/snakeToCamel';

interface IGetAttendeeListParams {
  tbmId: number;
  pageParam: number;
  pageSize?: number;
}

const getAttendeeList = async (values: IGetAttendeeListParams) => {
  const { tbmId, pageParam, pageSize = 10 } = values;

  const { data } = await axiosClient.get(`/attendee?tbm_id=${tbmId}&page_num=${pageParam}&page_size=${pageSize}`);

  return {
    result: snakeToCamel(data.data),
    nextPage: pageParam + 1,
    isLast: !!data.next,
  };
};

const useGetAttendeeListQuery = () => {
  const { id: tbmId } = useRecoilValue(tbmInfoState);

  return useInfiniteQuery(
    ['participant', 'attendeeList', tbmId],
    ({ pageParam = 1 }) => getAttendeeList({ tbmId, pageParam }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.isLast) return lastPage.nextPage;
        return undefined;
      },
      onError: (error) => {
        console.error('get attendee list error', error);
      },
    },
  );
};

export default useGetAttendeeListQuery;
