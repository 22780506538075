import { useMutation } from '@tanstack/react-query';
import axiosClient from 'libs/axios/axios';
import { IRecordingInfo } from 'types/recording';
import { usePatchRecordingQuery } from './usePatchRecordingQuery';

const patchBugReport = async (recordingInfo: IRecordingInfo) => {
  const { ruleId, attendeeId, tbmId } = recordingInfo;
  const { data } = await axiosClient.post('/attendee/safety-rule-log/error', {
    log_id: ruleId,
    attendee_id: attendeeId,
    tbm_id: tbmId,
  });

  return { data, recordingInfo };
};

export const useBugReportQuery = () => {
  const { mutate: patchRecording } = usePatchRecordingQuery();

  return useMutation(patchBugReport, {
    onSuccess: (response) => {
      const { recordingInfo } = response;
      patchRecording(recordingInfo);
    },
    onError: (e) => {
      console.error('bugReporting Error', e);
      alert('버그리포팅에 실패하였습니다. 관리자에게 문의하세요');
    },
  });
};
