/* eslint-disable @typescript-eslint/naming-convention */
import { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled, { css } from 'styled-components';

import { announcementState } from 'pages/recoil/announcement';
import { fontScaleState } from 'pages/recoil/fonts';
import { useAnnouncementQuery } from 'pages/Announcement/queries/useAnnouncementQuery';

import { font } from 'styles/fonts';

import { IAnnouncementList } from 'types/announcement';
import { IScaledText } from 'types/fonts';

export default function AnnounceList() {
  const navigate = useNavigate();

  const [fontScale, setFontScale] = useRecoilState(fontScaleState);
  const { category } = useRecoilValue(announcementState);
  const { data: announcementList } = useAnnouncementQuery(category);

  const handleNavigate = (detailId: number) => {
    navigate(`detail/${detailId}`);
  };

  useLayoutEffect(() => {
    const fontScaleValue = Number(localStorage.getItem('fontScale'));
    setFontScale(fontScaleValue);
  }, []);

  return (
    <Container fontScale={fontScale}>
      {announcementList?.map((item: IAnnouncementList, idx: number) => (
        <ContentWrap key={`content-${idx}`} onClick={() => handleNavigate(item.id)}>
          <Header>
            <Title emergency={item.category === '긴급'}>{item.category}</Title>
            <ContentDate>{item.createdAt}</ContentDate>
          </Header>
          <ContentText>{item.title}</ContentText>
        </ContentWrap>
      ))}
    </Container>
  );
}

const NormalText = styled.p``;
const BoldText = styled.p``;

const Container = styled.div<IScaledText>`
  height: 100vh;
  width: 100%;
  padding: 0 15px 80px;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
    width: 0;
  }
  -ms-overflow-style: none;

  ${NormalText} {
    ${({ fontScale }) => font(14 + fontScale, 400, (14 + fontScale) * 1.5)}
  }

  ${BoldText} {
    ${({ fontScale }) => font(14 + fontScale, 700, (14 + fontScale) * 1.5)}
  }
`;

const ContentWrap = styled.div`
  width: 100%;
  min-height: 101px;
  background: #ffffff;
  margin-bottom: 19px;
  border-radius: 10px;
  padding: 21px;
  cursor: pointer;
`;

const Header = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 18px;
`;

const Title = styled(NormalText)<{ emergency?: boolean }>`
  color: #999999;

  ${({ emergency }) =>
    emergency &&
    css`
      color: red;
    `}
`;

const ContentDate = styled(Title)`
  width: 140px;
`;

const ContentText = styled(BoldText)`
  color: #000000;
`;
