import { useState } from 'react';
import styled from 'styled-components';

import { flex } from 'styles/flex';
import { font } from 'styles/fonts';

interface ITemplateTitleInput {
  saveRequest?: Function;
}

function TemplateTitleInput({ saveRequest }: ITemplateTitleInput) {
  const [title, setTitle] = useState('');

  const handleBtnClick = () => {
    alert('비회원은 제외되고 저장됩니다.');
    saveRequest({ isCompleted: true, isTemplate: true, templateTitle: title });
  };

  return (
    <Container>
      <Input>
        <InputField placeholder="템플릿이름을 입력해주세요" value={title} onChange={(e) => setTitle(e.target.value)} />
      </Input>
      <BtnWrap onClick={handleBtnClick}>
        <ConfirmBtn>확인</ConfirmBtn>
      </BtnWrap>
    </Container>
  );
}

export default TemplateTitleInput;

const Container = styled.div`
  ${flex('', 'center', 'column')}
`;

const Input = styled.div`
  background: #ffffff;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  height: 42px;
  margin: 30px 0;
  padding: 12px;
  width: 100%;
`;

const InputField = styled.input`
  border: none;
  ${font(12, 400, 18)}
  width: 100%;
  height: 100%;
  ::placeholder {
    color: #999999;
  }
`;

const BtnWrap = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`;

const ConfirmBtn = styled.button`
  ${font(18, 500, 17)}
  ${flex('center', 'center')}
  height: 52px;
  flex: 1;
  border-radius: 5px;
  text-align: center;
  background: #15aedb;
  color: #ffffff;
`;
