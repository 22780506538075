import { useMutation } from '@tanstack/react-query';

import axiosClient from 'libs/axios/axios';

import { ITbmInfoType } from 'types/tbmManagement';

const saveTbm = async ({
  tbmInfo,
  date,
  isCompleted,
  isTemplate,
  templateTitle,
}: {
  tbmInfo: ITbmInfoType;
  date: string;
  isCompleted: boolean;
  isTemplate?: boolean;
  templateTitle?: string;
}) => {
  const {
    authorId,
    time,
    location,
    notice,
    operationType,
    operationDetailList,
    participantList,
    safetyRuleList,
    safetyMeasureList,
    emergencyProcedureList,
    attendeeList,
  } = tbmInfo;

  const tbmObj = {
    author_id: authorId,
    datetime: `${date} ${time}`,
    notice,
    location,
    is_complete: isTemplate ? false : isCompleted,
    is_template: isTemplate,
    operation_id: operationType || null,
    operation_detail_list: operationDetailList?.map((operation) => operation.id),
    safety_rule_list: safetyRuleList?.map((rule) => rule.id),
    safety_measure_list: safetyMeasureList?.map((measure) => measure.id),
    participant_list: participantList?.map((participant) => participant.id),
    emergency_procedure_list: emergencyProcedureList?.map((procedure) => procedure.id),
    attendee_list: attendeeList?.map((attendee) => attendee.id),
    ...(isTemplate && { template_title: templateTitle }),
  };

  const response = await axiosClient.post('/tbm', tbmObj);
  return response?.data;
};

const useSaveTbmQuery = ({ options }:{ options:any }) => {
 const { setActiveModal } = options;

 return useMutation(['tbm', 'save'], saveTbm, {
    onError: (error : any) => {
      alert(`${error?.response.data.detail}`);
      setActiveModal('');
      console.error('saveTbm error', error);
    },
  });
};

export default useSaveTbmQuery;
