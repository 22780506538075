/* eslint-disable max-len */
import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import { font } from 'styles/fonts';
import { flex } from 'styles/flex';

interface ITextInputProps extends HTMLAttributes<HTMLInputElement> {
  btnText?: string;
  disabled?: boolean;
  isError?: boolean;
  label?: string;
  subText?: {
    msg?: string; // 에러가 아닐 때 표시
    error?: string;
  };
  type: 'input' | 'buttonInput' | 'textArea';
  value?: string;
  onChangeInput?: Function;
  onBtnClick?: Function;
}

interface IInputProps {
  disabled: boolean;
  label: string;
  value: string;
  error?: boolean;
  onChange: Function;
}

interface IButtonInputProps {
  btnText: string;
  label: string;
  value: string;
  error?: boolean;
  onClick: Function;
}

interface ITextAreaProps {
  label: string;
  value: string;
  onChange: Function;
}

function TextInput({
  btnText,
  disabled,
  label,
  isError,
  subText = { error: '필수 항목입니다' },
  type,
  value,
  onChangeInput,
  onBtnClick,
}: ITextInputProps) {
  const MAPPING_COMPONENT = {
    input: <TextInput.Input disabled={disabled} error={isError} label={label} value={value} onChange={onChangeInput} />,
    buttonInput: (
      <TextInput.ButtonInput btnText={btnText} error={isError} label={label} value={value} onClick={onBtnClick} />
    ),
    textArea: <TextInput.TextArea label={label} value={value} onChange={onChangeInput} />,
  };

  return (
    <Wrapper>
      {MAPPING_COMPONENT[type]}
      {subText && <SubText>{isError ? subText.error : subText.msg}</SubText>}
    </Wrapper>
  );
}

export default TextInput;

function Input({ disabled, label, value, onChange, error }: IInputProps) {
  return (
    <InputWrap error={error}>
      <Label error={error}>{label}</Label>
      <InputField
        disabled={disabled}
        type={label === '예정시간' ? 'time' : 'text'}
        value={value}
        onChange={(e) => onChange(e)}
      />
    </InputWrap>
  );
}

function ButtonInput({ btnText, label, value, onClick, error }: IButtonInputProps) {
  return (
    <>
      <ButtonInputWrap error={error} onClick={() => onClick()}>
        <Label error={error}>{label}</Label>
        <ButtonInputField disabled value={value} />
      </ButtonInputWrap>
      <Button error={error} onClick={() => onClick()}>
        {btnText}
      </Button>
    </>
  );
}

function TextArea({ label, value, onChange }: ITextAreaProps) {
  return (
    <TextAreaWrap>
      <Label>{label}</Label>
      <TextAreaField value={value} onChange={(e) => onChange(e)} />
    </TextAreaWrap>
  );
}

TextInput.Input = Input;
TextInput.ButtonInput = ButtonInput;
TextInput.TextArea = TextArea;

const Wrapper = styled.div`
  ${flex('space-between', '')}
  position: relative;
  width: 100%;
  background-color: #ffffff;
`;

const BaseWrap = styled.div<{ error?: boolean }>`
  ${flex('', 'center')}
  width: 100%;
  padding: 15px 15px 15px 12px;
  border: 1px solid #c0c0c0;
  ${({ error }) =>
    error &&
    css`
      border: 1px solid #ea3313;
      color: #ea3313;
    `}
`;

const InputWrap = styled(BaseWrap)`
  border-radius: 5px;
`;

const ButtonInputWrap = styled(BaseWrap)`
  border-radius: 5px 0 0 5px;
  border-right: none;
`;

const TextAreaWrap = styled(BaseWrap)`
  border-radius: 5px;
`;

const Label = styled.label<{ error?: boolean }>`
  ${font(12, 400, 18)};
  width: 110px;
  color: #15aedb;
  ${({ error }) =>
    error &&
    css`
      color: #ea3313;
    `}
`;

const InputField = styled.input`
  ${font(12, 400, 18)};
  color: #000000;
  width: calc(100% - 105px);
  padding-right: 30px;
  border-radius: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ButtonInputField = styled(InputField)`
  padding-right: 5px;
`;

const TextAreaField = styled.textarea`
  ${font(12, 400, 18)};
  width: calc(100% - 105px);
  height: 90px;
  padding-right: 30px;
  outline: none;
  border: none;
  border-radius: 5px;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  text-align: left;
  overflow: auto;
`;

const SubText = styled.div<{ isError?: boolean }>`
  ${font(12, 400, 18)};
  position: absolute;
  top: 56px;
  left: 12px;
  margin-top: 4px;
  color: #ea3313;
`;

const Button = styled.div<{ error?: boolean }>`
  ${flex('center', 'center')}
  ${font(12, 700, 14)}
  min-width: 50px;
  width: 50px;
  border: 1.2px solid #15aedb;
  border-radius: 0 4px 4px 0;
  color: #15aedb;
  ${({ error }) =>
    error &&
    css`
      color: #ea3313;
      border: 1.2px solid #ea3313;
    `}
`;
