import { useMutation, useQueryClient } from '@tanstack/react-query';
import axiosClient from 'libs/axios/axios';

const editSafetyRule = async (values: { targetId: number; inputValue: string }) => {
  const { targetId, inputValue } = values;

  const { data } = await axiosClient.patch(`/safety-rule/${targetId}`, {
    script: inputValue,
  });

  return data;
};

const useEditSafetyRuleQuery = () => {
  const queryClient = useQueryClient();

  return useMutation(['safetyRule', 'edit'], editSafetyRule, {
    onSuccess: () => {
      queryClient.invalidateQueries(['safetyRule', 'list']);
    },
    onError: (error) => {
      alert('수정 실패. 다시 한 번 시도해주세요');
      console.error('edit safetyRule error', error);
    },
  });
};

export default useEditSafetyRuleQuery;
