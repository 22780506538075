import dayjs from 'dayjs';
import { AxiosError } from 'axios';
import { useSetRecoilState } from 'recoil';
import { useQuery } from '@tanstack/react-query';

import { organizationChartState } from 'pages/recoil/announcement';

import axiosClient from 'libs/axios/axios';
import { snakeToCamel } from 'util/snakeToCamel';

import { IAnnouncementList } from 'types/announcement';

const fetchAnnouncement = async (category: string) => {
  const query = category ? `?category=${category}` : '';

  const { data } = await axiosClient.get(`/announcement/employee${query}`);

  const camelData = snakeToCamel(data);

  const chartData = {
    id: 0,
    title: '안전보건관리 조직도',
    category: '알림',
    createdAt: dayjs().format('YYYY.MM.DD'),
  };

  return [chartData, ...camelData];
};

export const useAnnouncementQuery = (category: string) => {
  const setOrganizationChart = useSetRecoilState(organizationChartState);

  return useQuery<IAnnouncementList[], AxiosError>(
    ['Announcement', 'list', category],
    () => fetchAnnouncement(category),
    {
      onSuccess(data) {
        const chartData = data.find((el) => el.title === '안전보건관리 조직도');

        return setOrganizationChart((prev) => ({
          ...prev,
          id: chartData.id,
          title: chartData.title,
          category: chartData.category,
          createdAt: chartData.createdAt,
          previous: data[1],
        }));
      },
      onError: (error) => {
        console.error('Announcement list error', error);
      },
    },
  );
};
