const compareScript = (targetScript: string, result: string) => {
    const parsedTargetScript = targetScript.split(' ');
    const parsedResult = result.split(' ');

    let index = 0;

    for (let i = 0; i < parsedTargetScript.length; i++) {
      if (parsedTargetScript[i] === parsedResult[i]) {
        index++;
      }
    }
    const readResult = parsedTargetScript.slice(0, index).join(' ');
    const unReadResult = parsedTargetScript.slice(index).join(' ');

    return { readResult, unReadResult };
  };

  export default compareScript;
