import { HTMLAttributes } from 'react';
import styled from 'styled-components';

import { flex } from 'styles/flex';
import { font } from 'styles/fonts';

interface IInputPropTypes extends HTMLAttributes<HTMLInputElement> {
  autoFocus?: boolean;
  isError: boolean;
  subText?: string;
  title: string;
  value: string;
  name: string;
  placeholder?: string;
  onChangeInput: Function;
}

function Input({ autoFocus, isError, subText, title, value, onChangeInput, name, placeholder }: IInputPropTypes) {
  return (
    <Wrapper>
      <InputWrapper isError={isError}>
        {!value && <Title>{title}</Title>}
        <InputField
          autoFocus={autoFocus}
          isError={isError}
          name={name}
          placeholder={placeholder}
          type="password"
          onChange={(e) => onChangeInput(e)}
        />
      </InputWrapper>
      {isError && <InputSubText>{subText}</InputSubText>}
    </Wrapper>
  );
}

export default Input;

interface IInputStyleProps {
  isError: boolean;
}

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 24px;
`;

const InputWrapper = styled.div<IInputStyleProps>`
  ${flex('center', 'center', 'row')};
  position: relative;
  width: 100%;
  height: 42px;

  border: 1px solid ${({ isError }) => (isError ? '#ea3313' : '#c0c0c0')};
  border-radius: 5px;
`;

const Title = styled.div`
  ${font(12, 400, 18)};
  margin-left: 15px;
  position: absolute;
  left: 0;
  color: #15aedb;
`;

const InputField = styled.input<IInputStyleProps>`
  ${font(12, 400, 18)};
  width: 100%;
  height: 100%;
  padding-left: 15px;
  color: ${({ isError }) => (isError ? '#ea3313' : '#000000')};
  font-family: sans-serif;

  ::placeholder {
    padding-left: 88px;
    ${font(12, 400, 18)};
    color: #999999;
  }
`;

const InputSubText = styled.div`
  ${font(12, 400, 14)};
  margin-top: 6px;
  padding-left: 15px;
  color: #ea3313;
`;
