import styled from 'styled-components';

import Table from '../Table';

import { useGetOrganizationChartQuery } from 'pages/Announcement/queries/useGetOrganizationQuery';

import { flex } from 'styles/flex';
import { font } from 'styles/fonts';

export default function OrganizationChart() {
  const { data } = useGetOrganizationChartQuery();

  return (
    <Container>
      <Table itemList={data?.supervisor} title="안전보건관리(총괄)책임자" />
      <Line />
      <FlexTableWrap>
        <Table itemList={data?.safetyManager} title="안전관리자" />
        <Margin />
        <Table itemList={data?.healthManager} title="보건관리자" />
      </FlexTableWrap>
      <Line />
      <Table itemList={data?.fieldManager} title="관리감독자" />
      <Line />
      <Content>산업안전 보건위원회</Content>
      <Line />
      <Content>전 근로자</Content>
    </Container>
  );
}

const Container = styled.div`
  ${flex('', 'center', 'column')}
  padding: 14px;
`;

const Line = styled.div`
  width: 1px;
  background: #d9d9d9;
  height: 24px;
  margin: 12px 0;
`;

const FlexTableWrap = styled.div`
  display: flex;
  width: 100%;
`;

const Margin = styled.div`
  margin: 0 8px;
`;

const Content = styled.div`
  border-radius: 4px;
  width: 80%;
  ${flex('center', 'center')}
  ${font(14, 400, 16)}
  background: #15AEDB;
  height: 40px;
  border-radius: 4px;
  color: #ffffff;
`;
