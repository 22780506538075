import { useMutation, useQueryClient } from '@tanstack/react-query';
import axiosClient from 'libs/axios/axios';

const deleteOperationDetail = async (idList: number[]) => {
  const { data } = await axiosClient.post('/operation/detail/delete', {
    id_list: idList,
  });

  return data;
};

const useDeleteOperationDetailQuery = () => {
  const queryClient = useQueryClient();

  return useMutation(['operationDetail', 'delete'], deleteOperationDetail, {
    onSuccess: () => {
      queryClient.invalidateQueries(['operationDetail', 'list']);
    },
    onError: (error) => {
      alert('삭제 실패. 다시 한 번 시도해주세요');
      console.error('delete operation detail error', error);
    },
  });
};

export default useDeleteOperationDetailQuery;
