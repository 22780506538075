import styled from 'styled-components';
import { useRecoilValue } from 'recoil';

import { selectedDateState } from 'pages/recoil/createTBM';

import { flex } from 'styles/flex';
import { font } from 'styles/fonts';
import { useTBMByDateQuery } from 'pages/CreateTBM/queries/useTBMByDateQuery';

interface ICalendarPropTypes {
  dayArray: string[];
  postMessageToRN: (selectedDate: string, id: number | null, idType: string) => void;
}

export default function TBMList({ dayArray, postMessageToRN }: ICalendarPropTypes) {
  const { data: tbmList } = useTBMByDateQuery();
  const selectedDate = useRecoilValue(selectedDateState);
  const selectedDateTbmList = tbmList?.filter((el) => el.date === selectedDate.format('YYYY-MM-DD'));

  const handleTbmListClick = (id: number) => {
    postMessageToRN(`${selectedDate.format('YYYY-MM-DD')}(${dayArray[selectedDate.day()]})`, id, 'tbm');
  };

  return (
    <TBMListContainer>
      {selectedDateTbmList?.map(({ id, date, time, isComplete, operationName, operationDetail }) => (
        <TBMListWrap key={id} onClick={() => handleTbmListClick(id)}>
          <TitleWrap>
            <Title>{operationName}</Title>
            <Date>{date}</Date>
          </TitleWrap>
          <ContentWrap>
            <Content>{operationDetail}</Content>
            <Time isComplete={isComplete}>{isComplete ? `${time}` : '임시저장'}</Time>
          </ContentWrap>
        </TBMListWrap>
      ))}
    </TBMListContainer>
  );
}

const TBMListContainer = styled.div`
  ${flex('', 'center', 'column')}
  margin-top: 10px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const TBMListWrap = styled.div`
  width: 100%;
  margin-bottom: 10px;
  padding: 24px;
  border-radius: 10px;
  background-color: white;
`;

const TitleWrap = styled.div`
  ${flex('space-between', 'center')}
`;

const Title = styled.span`
  ${font(14, 700, 21)}
  width: 165px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Date = styled.span`
  ${font(14, 400, 21)}
  color: #999999;
`;

const ContentWrap = styled.div`
  ${flex('space-between', 'center')}
  margin-top: 18px;
`;

const Content = styled.span`
  ${font(14, 400, 21)}
  width: 165px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Time = styled.span<{ isComplete: boolean }>`
  ${font(14, 700, 21)}
  color: ${({ isComplete }) => (isComplete ? '#15AEDB' : '#EA3313')};
`;
