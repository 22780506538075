import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import axiosClient from 'libs/axios/axios';
import { IAttendeeInfo } from 'types/attendeeTbm';

import { snakeToCamel } from 'util/snakeToCamel';

const getAttendeeInfo = async (attendeeId: number, queryString: string) => {
  const { data } = await axiosClient.get(`/attendee/${attendeeId}/info${queryString}`);

  const cameled = snakeToCamel(data);
  return cameled;
};

export const useGetAttendeeInfo = (attendeeId: number, queryString: string) =>
  useQuery<IAttendeeInfo, AxiosError>(['attendee', 'info'], () => getAttendeeInfo(attendeeId, queryString), {
    onError: (error) => {
      console.error(`get attendee info error ${error}`);
    },
  });
