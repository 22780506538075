import dayjs, { Dayjs } from 'dayjs';
import { atom } from 'recoil';

export const selectedDateState = atom<Dayjs>({
  key: 'selectedDateState',
  default: dayjs(),
});

export const dateRangeState = atom({
  key: 'dateRangeState',
  default: {
    startDate: dayjs().startOf('week'),
    endDate: dayjs().endOf('week'),
  },
});
