import { useRef, useState } from 'react';
import compareScript from 'util/compareScript';
import useVoiceMark from './useVoiceMark';

const initialSocketInfo = {
  connectionStatus: 'DISCONNECT',
  isSocketOpen: false,
  audioUrl: '',
  readRule: '',
  unReadRule: '',
  isRecordingCompleted: false,
  error: '',
};

const SAMPLE_RATE = 16000;

const useSTT = () => {
  const { saveAudioBuffer } = useVoiceMark();
  const socketRef = useRef<null | WebSocket>(null);
  const streamRef = useRef<MediaStream>(null);
  const contextRef = useRef(null);
  const bufferRef = useRef(null);
  const targetRuleRef = useRef('');
  const [socketInfo, setSocketInfo] = useState(initialSocketInfo);

  const { connectionStatus, isSocketOpen, error, readRule, unReadRule, isRecordingCompleted, audioUrl } = socketInfo;

  const convertFloat32ToInt16 = (buffer) => {
    let l = buffer.length;
    const buf = new Int16Array(l);
    while (l--) {
      buf[l] = Math.min(1, buffer[l]) * 0x7fff;
    }
    return buf;
  };

  const processAudio = async (event) => {
    const left = event.inputBuffer.getChannelData(0);
    const buffer = convertFloat32ToInt16(left);

    if (bufferRef.current) {
      bufferRef.current = [...bufferRef.current, ...buffer];
    } else {
      bufferRef.current = buffer;
    }

    try {
      socketRef.current.send(buffer);
    } catch (e) {
      console.error('socket error: ', e);
    }

    // while (tempBufferRef.current.length >= SAMPLE_RATE) {
    //   const targetChunk = tempBufferRef.current.slice(0, SAMPLE_RATE);
    //   tempBufferRef.current = tempBufferRef.current.slice(SAMPLE_RATE, tempBufferRef.current.length);
    //   try {
    //     socketRef.current.send(new Int16Array(targetChunk));
    //   } catch (e) {
    //     return;
    //   }
    // }
  };

  const disconnectTrack = () => {
    streamRef.current.getTracks().forEach((track) => {
      track.stop();
    });
  };

  const openSocket = async (targetRule: string, attendeeId: number) => {
    bufferRef.current = null;
    targetRuleRef.current = targetRule;
    const openConfig = {
      config: {
        initial_sil_time_limit: 10.0,
        final_sil_time_limit: 10.0,
        word_err_limit: 5,
        status: 'OPEN',
        speaker_name: '',
        mode: 'textalignment',
        access_token: '',
        userid: `${attendeeId}`,
        auth_sentence: targetRule,
      },
    };
    const ws = new WebSocket(process.env.REACT_APP_TEXT_ALIGNMENT_SERVER);
    socketRef.current = ws;

    ws.onopen = async () => {
      ws.send(JSON.stringify(openConfig));
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: {
            echoCancellation: true,
            sampleRate: SAMPLE_RATE,
          },
        });
        streamRef.current = stream;

        const audioContext = new AudioContext({ sampleRate: SAMPLE_RATE });
        contextRef.current = audioContext;
        const source = audioContext.createMediaStreamSource(stream);

        // bufferSize can be  256, 512, 1024, 2048, 4096, 8192, 16384,
        const processor = audioContext.createScriptProcessor(8192, 1, 1);

        source.connect(processor);
        processor.connect(audioContext.destination);
        processor.addEventListener('audioprocess', (event) => {
          if (!socketRef.current) return;
          processAudio(event);
        });
      } catch (e) {
        console.error('error: ', e);
      }
    };

    ws.onmessage = async (event) => {
      const parsedResult = JSON.parse(event.data);
      const { status } = parsedResult;
      let transcript = '';
      let readScript = '';
      let unReadScript = '';

      if ('transcript' in parsedResult) {
        transcript = parsedResult.transcript;
        const { readResult, unReadResult } = compareScript(targetRule, transcript);
        readScript = readResult;
        unReadScript = unReadResult;
      }

      switch (status) {
        case 'CONNECTED':
          return setSocketInfo((prev) => ({
            ...prev,
            error: '',
            connectionStatus: status,
            isRecordingCompleted: false,
            isSocketOpen: true,
            url: parsedResult.audio_url,
            readRule: '',
            unReadRule: targetRule,
          }));
        case 'TRANSCRIPT_UPDATE': {
          return setSocketInfo((prev) => ({
            ...prev,
            connectionStatus: status,
            readRule: readScript,
            unReadRule: unReadScript,
          }));
        }
        case 'TRANSCRIPT_SUCCESS': {
          setSocketInfo((prev) => ({
            ...prev,
            connectionStatus: status,
            isRecordingCompleted: true,
            isSocketOpen: false,
            readRule: targetRule,
            unReadRule: '',
          }));
          closeSocket();
          saveAudioBuffer(bufferRef.current);
          return;
        }
        case 'INIT_SIL_TIME_EXCEEDED': {
          setSocketInfo((prev) => ({
            ...prev,
            connectionStatus: status,
            isSocketOpen: false,
            error: status,
          }));
          return closeSocket();
        }
        case 'FINAL_SIL_TIME_EXCEEDED': {
          setSocketInfo((prev) => ({
            ...prev,
            connectionStatus: status,
            isSocketOpen: false,
            error: status,
          }));
          return closeSocket();
        }
        case 'WORD_ERR_EXCEEDED': {
           setSocketInfo((prev) => ({
            ...prev,
            connectionStatus: status,
            isSocketOpen: false,
            error: status,
          }));
          return closeSocket();
        }
        default: {
          setSocketInfo((prev) => ({
            ...prev,
            connectionStatus: status,
            isSocketOpen: false,
            unReadRule: targetRule,
            error: status,
          }));
        }
      }
    };

    ws.onclose = async () => {
      disconnectTrack();
      contextRef.current = null;
    };

    ws.onerror = () => {
      contextRef.current = null;
      socketRef.current = null;
      disconnectTrack();
      alert('일시적으로 연결이 되지 않습니다.');
      setSocketInfo((prev) => ({
        ...prev,
        // error: e?.message,
        connectionStatus: 'CLOSE',
        isSocketOpen: false,
        unReadRule: targetRule,
      }));
    };
  };

  const closeSocket = async () => {
    if (socketRef.current) {
      try {
        socketRef.current.send('EOS');
        socketRef.current.close();
        setSocketInfo((prev) => ({
          ...prev,
          isSocketOpen: false,
          connectionStatus: 'CLOSE',
        }));
        socketRef.current = null;
        contextRef.current.close();
      } catch (e) {
        console.error('closeError', e);
      }
    }
  };

  return {
    openSocket,
    closeSocket,
    isSocketOpen,
    readRule,
    unReadRule,
    isRecordingCompleted,
    connectionStatus,
    error,
    audioUrl,
  };
};

export default useSTT;
