import { ChangeEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import Input from './components/Input';

import { useResetPasswordQuery } from './queries/useResetPasswordQuery';

import { mobilePasswordCheck } from 'util/regExp';
import { fitImg } from 'styles/mixins';
import { font } from 'styles/fonts';
import { flex } from 'styles/flex';

export default function EmployeeResetPassword() {
  const { search } = useLocation();
  const {
    mutate: submitPassword,
    isSuccess: resetSuccess,
    isError: resetPasswordError,
    reset,
  } = useResetPasswordQuery();
  const [token, setToken] = useState('');

  const [password, setPassword] = useState({
    newPassword: '',
    passwordCheck: '',
  });
  const [passwordError, setPasswordError] = useState({
    newPasswordError: false,
    passwordCheckError: false,
  });

  const { newPassword, passwordCheck } = password;
  const { newPasswordError, passwordCheckError } = passwordError;

  const handlePasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (resetPasswordError) {
      reset();
    }
    setPasswordError({ newPasswordError: false, passwordCheckError: false });

    setPassword({
      ...password,
      [name]: value,
    });
  };

  const handlePasswordSubmit = () => {
    if (!mobilePasswordCheck(newPassword)) {
      setPasswordError((prev) => ({ ...prev, newPasswordError: true }));
      return;
    }

    if (newPassword !== passwordCheck) {
      setPasswordError((prev) => ({ ...prev, passwordCheckError: true }));
      return;
    }

    submitPassword({ newPassword, token });
  };

  useEffect(() => {
    setToken(search.split('=')[1]);
  }, []);

  return (
    <Background>
      <ScreenWrap>
        <LogoWrap>
          <Symbol>
            <img alt="symbol" src="/icons/ic-logo-symbol.png" />
          </Symbol>
          <MainLogo>
            <img alt="main-logo" src="/icons/ic-logo.png" />
          </MainLogo>
        </LogoWrap>
        <Title>비밀번호 재설정</Title>
        {resetSuccess ? (
          <MessageBox>
            {
              '비밀번호 재설정이 완료되었습니다. \n이제 VOICESAFETY 앱으로 돌아가서 \n 재 설정한 비밀번호로 로그인해주세요.'
            }
          </MessageBox>
        ) : (
          <>
            <InputBox>
              <Input
                isError={newPasswordError}
                name="newPassword"
                placeholder="영문/숫자를 조합하여 8-15자"
                subText="비밀번호는 영문/숫자 혼합 8-15자여야 합니다."
                title="새 비밀번호"
                value={newPassword}
                onChangeInput={(e: ChangeEvent<HTMLInputElement>) => handlePasswordInput(e)}
              />
              <Input
                isError={passwordCheckError}
                name="passwordCheck"
                subText="비밀번호가 일치하지 않습니다."
                title="새 비밀번호 확인"
                value={passwordCheck}
                onChangeInput={(e: ChangeEvent<HTMLInputElement>) => handlePasswordInput(e)}
              />
            </InputBox>
            <Btn onClick={handlePasswordSubmit}>확인</Btn>
          </>
        )}
      </ScreenWrap>
    </Background>
  );
}

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: white;

  ::-webkit-scrollbar {
    display: none;
    width: 0;
  }
  -ms-overflow-style: none;
`;

const ScreenWrap = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 44px 15px;
  max-width: 500px;
`;

const Symbol = styled.div`
  width: 34px;
  height: 57px;
  ${fitImg}
`;

const MainLogo = styled.div`
  width: 183px;
  height: 34px;
  ${fitImg}
`;

const LogoWrap = styled.div`
  ${flex('center', 'center')}
  gap: 9px;
`;

const Title = styled.h1`
  ${font(20, 700, 18)}
  margin-top: 27px;
  text-align: center;
`;

const InputBox = styled.div`
  ${flex('space-between', '', 'column')}
  margin-top: 111px;
`;

const Btn = styled.button`
  ${font(18, 700, 27)}
  width: 100%;
  margin-top: 36px;
  padding: 12px 0px 13px;
  border-radius: 5px;
  background-color: #15aedb;
  color: white;
`;

const MessageBox = styled.div`
  ${font(14, 400, 27)}
  padding: 24px;
  border: 1px solid #15aedb;
  border-radius: 5px;
  white-space: pre-line;
  text-align: center;
  margin-top: 117px;
`;
