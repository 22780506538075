import { useState } from 'react';
import styled from 'styled-components';

import Calendar from './components/Calendar';
import TBMList from './components/TBMList';
import TemplateModal from './components/TemplateModal';

const DAY_ARRAY = ['일', '월', '화', '수', '목', '금', '토'];

export default function CreateTBM() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const postMessageToRN = (selectedDate: string, id: number | null, idType: string) => {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        selectedDate,
        id,
        idType,
      }),
    );
  };

  return (
    <Container>
      <Calendar dayArray={DAY_ARRAY} handleModal={handleModal} postMessageToRN={postMessageToRN} />
      <TBMList dayArray={DAY_ARRAY} postMessageToRN={postMessageToRN} />
      <TemplateModal handleModal={handleModal} isModalOpen={isModalOpen} postMessageToRN={postMessageToRN} />
    </Container>
  );
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 20px 15px 40px;
  background-color: #fafafa;
`;
