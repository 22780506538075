import { useInfiniteQuery } from '@tanstack/react-query';
import axiosClient from 'libs/axios/axios';

interface IGetManagerParams {
  pageParam: number;
  pageSize?: number;
  keyword?: string;
}

const fetchGetManager = async (values: IGetManagerParams) => {
  const { pageParam, pageSize = 10, keyword } = values;

  const { data } = await axiosClient.get(
    `/tbm/manager-list?page_num=${pageParam}&page_size=${pageSize}&keyword=${keyword}`,
  );

  return {
    result: data.data,
    nextPage: pageParam + 1,
    isLast: !!data.next,
  };
};
export const useGetManagerQuery = (keyword: string) =>
  useInfiniteQuery(['manager', keyword], ({ pageParam = 1 }) => fetchGetManager({ pageParam, keyword }), {
    getNextPageParam: (lastPage) => {
      if (lastPage.isLast) return lastPage.nextPage;
      return undefined;
    },
    onError: (error) => {
      console.error('get manager list error', error);
    },
  });
