import { useEffect } from 'react';

import axiosClient from 'libs/axios/axios';

import getUrlParameter from 'util/getUrlParameter';
import { getSession } from 'util/sessionStorage';

function SelfAuthenticationResult() {
  useEffect(() => {
    const getNiceApiResult = async () => {
      const enc_data = getUrlParameter('enc_data');
      const uuid = getSession('uuid');

      if (!uuid) {
        alert('본인 인증 실패');
        return;
      }

      try {
        const { data } = await axiosClient.post('/niceapi/certification', {
          enc_data,
          device_id: uuid,
        });

        if (window.ReactNativeWebView) {
          return window.ReactNativeWebView.postMessage(JSON.stringify({ status: 'success', data }));
        }
      } catch (e) {
        console.error('authenticationResult error: ', e);
      }
    };

    getNiceApiResult();
  }, []);

  return <div></div>;
}

export default SelfAuthenticationResult;
