export const formatPhone = (value: string) => {
  let replaced = value.replace(/\s/g, '');
  const phone = replaced.replace(/-/g, '');

  if (phone.length > 4 && phone.length < 8) {
    replaced = `${phone.slice(0, 3)}-${phone.slice(3)}`;
  } else if (phone.length >= 8) {
    replaced = `${phone.slice(0, 3)}-${phone.slice(3, 7)}-${phone.slice(7)}`;
  }

  return replaced;
};
