import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import useIntersection from 'hooks/useInterSect';
import useDebounce from 'hooks/useDebounce';
import { TBM_INFO_DATA } from 'pages/TbmManagement/constant';

import { flex } from 'styles/flex';
import { font } from 'styles/fonts';

interface IListTabProps {
  dataKey: string;
  deleteEnable: boolean;
  query: any;
  targetNumberList: number[];
  handleCancelBtnClick: () => void;
  handleConfirmBtnClick: () => void;
  handleDeleteBtnClick: () => void;
  handleItemClick: (id: number, clickedValue: string) => void;
  handleItemEditBtnClick: (id: number, value: string) => void;
  onChangeKeyword: Function;
}

function ListTab({
  dataKey,
  deleteEnable,
  query,
  targetNumberList,
  handleCancelBtnClick,
  handleConfirmBtnClick,
  handleDeleteBtnClick,
  handleItemClick,
  handleItemEditBtnClick,
  onChangeKeyword,
}: IListTabProps) {
  const { hasNextPage, isFetching, isLoading, fetchNextPage, data: queryData } = query;
  const [searchKeyword, setSearchKeyword] = useState('');
  const debounceSearchKeyword = useDebounce({ value: searchKeyword });

  const data = useMemo(() => queryData?.pages.flatMap(({ result }: { result: [] }) => result) || [], [queryData]);

  const ref = useIntersection(async (entry, observer) => {
    observer.unobserve(entry.target);
    if (hasNextPage && !isFetching) {
      fetchNextPage();
    }
  });

  const handleSearchKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(e.target.value);
  };

  const contents = useMemo(
    () =>
      deleteEnable ? (
        <DeleteDummy>{`${targetNumberList.length}개의 ${TBM_INFO_DATA[dataKey].text} 삭제하시겠습니까?`}</DeleteDummy>
      ) : (
        <InfiniteScrollContainer>
          <ItemBox>
            {data?.map((item, idx) => {
              const { id } = item;
              const value = item[`${TBM_INFO_DATA[dataKey].value}`];
              return (
                <ItemWrap key={`item-${idx}`}>
                  <Item
                    key={`item-${idx}`}
                    isActive={targetNumberList.includes(id)}
                    onClick={() => handleItemClick(id, value)}
                  >
                    {value}
                  </Item>
                  {targetNumberList.includes(id) && (
                    <EditText onClick={() => handleItemEditBtnClick(id, value)}>수정</EditText>
                  )}
                </ItemWrap>
              );
            })}
          </ItemBox>
          <InfiniteScrollTarget ref={ref} />
        </InfiniteScrollContainer>
      ),
    [deleteEnable, data, targetNumberList],
  );

  useEffect(() => {
    onChangeKeyword((prev) => ({ ...prev, [dataKey]: debounceSearchKeyword }));
    return () => onChangeKeyword((prev) => ({ ...prev, [dataKey]: '' }));
  }, [debounceSearchKeyword]);

  return (
    <>
      <Input>
        <InputField placeholder="검색 및 입력해 주세요." value={searchKeyword} onChange={handleSearchKeyword} />
      </Input>
      <Contents>{isLoading ? <span>Loading...</span> : contents}</Contents>
      <BtnList>
        <BtnWrap>
          <DeleteBtn disabled={!targetNumberList.length} onClick={() => handleDeleteBtnClick()}>
            삭제
          </DeleteBtn>
        </BtnWrap>
        <BtnWrap>
          {deleteEnable ? (
            <CancelBtn onClick={() => handleCancelBtnClick()}>취소</CancelBtn>
          ) : (
            <ConfirmBtn onClick={() => handleConfirmBtnClick()}>확인</ConfirmBtn>
          )}
        </BtnWrap>
      </BtnList>
    </>
  );
}

export default ListTab;

const Input = styled.div`
  background: #ffffff;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  height: 42px;
  margin-bottom: 24px;
  padding: 12px;
  position: relative;
`;

const InputField = styled.input`
  ${font(12, 400, 18)}
  width: 100%;
  height: 100%;
  border: none;

  ::placeholder {
    color: #999999;
  }
`;

const Contents = styled.div`
  height: 202px;
  margin-bottom: 30px;
`;

const DeleteDummy = styled.div`
  ${flex('center', 'center')};
  ${font(12, 400, 18)};
  color: #ffffff;
  width: 100%;
  height: 100%;
  margin-bottom: 30px;
  background: #15aedb;
  border-radius: 4px;
`;

const ItemBox = styled.div`
  ${flex('', '', 'column')};
  height: fit-content;
  gap: 10px;
`;

const ItemWrap = styled.div`
  position: relative;
`;

const Item = styled.div<{ isActive?: boolean }>`
  ${font(12, 400, 14)};
  min-height: 32px;
  padding: 9px 40px 12px 8px;
  background: ${({ isActive }) => (isActive ? '#aaecff' : '#DDDDDD')};
  border-radius: 4px;
  color: #000000;
`;

const EditText = styled.p`
  ${font(12, 400, 14)};
  ${flex('flex-end', 'center')};
  position: absolute;
  height: 100%;
  padding: 0 12px;
  top: 0px;
  right: 0px;
  color: #ea3313;
  cursor: pointer;
`;

const BtnList = styled.div`
  ${flex('', 'center')}
  flex: 1;
  gap: 10px;
`;

const BtnWrap = styled.div`
  display: flex;
  flex: 1;
`;

const BaseBtn = styled.button`
  ${font(18, 500, 17)}
  ${flex('center', 'center')}
  height: 52px;
  flex: 1;
  border-radius: 5px;
  text-align: center;
  color: #ffffff;
`;

const ConfirmBtn = styled(BaseBtn)`
  background: #15aedb;
`;

const CancelBtn = styled(BaseBtn)`
  background: #999999;
`;

const DeleteBtn = styled(BaseBtn)`
  background: #ea3313;
`;

const InfiniteScrollContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

const InfiniteScrollTarget = styled.div`
  height: 1px;
`;
