/* eslint-disable react/jsx-one-expression-per-line */
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import dayjs from 'dayjs';

import { useGetAttendeeTbm } from './queries/useGetAttendeeTbm';
import Header from 'pages/components/Header';

import { flex } from 'styles/flex';
import { font } from 'styles/fonts';
import useFileDownload from 'hooks/useFileDownload';

const DAY_ARRAY = ['일', '월', '화', '수', '목', '금', '토'];

export default function AttendeeTbm() {
  const { attendeeId, tbmId } = useParams();
  const { download: handleFileDownload } = useFileDownload();
  const location = useLocation();
  const navigate = useNavigate();

  const { data: tbmData } = useGetAttendeeTbm(tbmId, attendeeId, location.search);

  const [isChecked, setIsChecked] = useState(false);

  const handleChecked = () => {
    setIsChecked((prev) => !prev);
  };

  const handleConfirmBtnClick = () => {
    navigate(`/attendee/${attendeeId}/safety-rule${location.search}&tbm_id=${tbmId}`);
  };

  return (
    <Container>
      <Header showPrevBtn={false} title={`${tbmData?.date}(${DAY_ARRAY[dayjs(tbmData?.date).day()]})`} />
      <TbmContainer>
        <OperationName>{tbmData?.operationName}</OperationName>
        <li>
          <Title>예정시간</Title>
          <Content>{tbmData?.time}</Content>
        </li>
        <li>
          <Title>
            <p>안전미팅 담당자</p>
          </Title>
          <Content>
            <p>{tbmData?.author}</p>
          </Content>
        </li>
        <li>
          <Title>
            <p>현장</p>
          </Title>
          <Content>
            <p>{tbmData?.location}</p>
          </Content>
        </li>
        {tbmData?.announcement.length > 0 && (
          <li>
            <Title>
              <p>전체 공지 사항</p>
            </Title>
            {tbmData.announcement.map((announcement, index) => (
              <Content key={`announcemt-${index}`}>
                <p>{announcement.content}</p>
                <AttachmentWrap>
                  <Title>
                    <p>첨부파일</p>
                  </Title>
                  {announcement.attachments?.map((attachment, idx) => (
                    <AttachmentFileName
                      key={`attachment-${index}-${idx}`}
                      onClick={() => handleFileDownload(attachment.name, attachment.path)}
                    >
                      {attachment.name}
                    </AttachmentFileName>
                  ))}
                </AttachmentWrap>
              </Content>
            ))}
          </li>
        )}
        {tbmData?.notice && (
          <li>
            <Title>
              <p>전달사항</p>
            </Title>
            <Content>
              <p>{tbmData?.notice}</p>
            </Content>
          </li>
        )}
        {tbmData?.operationName && (
          <li>
            <Title>
              <p>작업유형</p>
            </Title>
            <Content>
              <p>{tbmData?.operationName}</p>
            </Content>
          </li>
        )}
        {tbmData?.operationDetails.length > 0 && (
          <li>
            <Title>
              <p>작업내용</p>
            </Title>
            <Content>
              {tbmData?.operationDetails.map(({ id, explanation }) => (
                <p key={id}>{explanation}</p>
              ))}
            </Content>
          </li>
        )}

        {tbmData?.safetyMeasures.length > 0 && (
          <li>
            <Title>
              <p>위험요소</p>
            </Title>
            <Content>
              {tbmData?.safetyMeasures.map(({ id, name }, idx) => (
                <p key={id}>
                  {idx + 1}. {name}
                </p>
              ))}
            </Content>
          </li>
        )}

        {tbmData?.safetyRules.length > 0 && (
          <li>
            <Title>
              <p>안전수칙</p>
            </Title>
            <Content>
              {tbmData?.safetyRules.map(({ id, script }, idx) => (
                <p key={id}>
                  {idx + 1}. {script}
                </p>
              ))}
            </Content>
          </li>
        )}

        {tbmData?.emergencyProcedures.length > 0 && (
          <li>
            <Title>
              <p>비상 시 대처요령</p>
            </Title>
            <Content>
              {tbmData?.emergencyProcedures.map(({ id, value }, idx) => (
                <p key={id}>
                  {idx + 1}. {value}
                </p>
              ))}
            </Content>
          </li>
        )}
      </TbmContainer>
      <CheckBoxWrap>
        <CheckBox isChecked={isChecked} onClick={() => handleChecked()} />
        <CheckText>오늘의 안전 미팅 내용을 숙지 하였습니다.</CheckText>
      </CheckBoxWrap>
      <ConfirmBtn disabled={!isChecked} onClick={() => handleConfirmBtnClick()}>
        확인
      </ConfirmBtn>
    </Container>
  );
}

const Container = styled.div`
  padding: 32px 15px;
  background-color: #fafafa;

  ::-webkit-scrollbar {
    display: none;
    width: 0;
  }
  -ms-overflow-style: none;
`;

const TbmContainer = styled.ul`
  background-color: white;
  border-radius: 10px;
  padding: 24px;

  li {
    margin-bottom: 18px;
    padding-bottom: 18px;
    border-bottom: 1px solid #d9d9d9;

    :last-child {
      border: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
`;

const OperationName = styled.div`
  ${font(18, 700, 21)}
  margin-bottom: 24px;
  text-align: center;
`;

const Title = styled.div`
  ${font(14, 400, 21)};
  margin-bottom: 12px;
  white-space: pre-wrap;
  color: #999999;
`;

const Content = styled.div`
  ${font(14, 700, 21)};
  white-space: pre-wrap;
  color: #000000;

  p {
    margin-bottom: 12px;

    :last-child {
      margin-bottom: 0;
    }
  }
`;

const CheckBoxWrap = styled.div`
  ${flex('flex-start', 'center')}
  margin-top: 30px;
  padding: 3.75px;
`;

const CheckBox = styled.div<{ isChecked: boolean }>`
  width: 22.5px;
  height: 22.5px;
  margin-right: 13.75px;
  border: 1px solid #c0c0c0;
  border-radius: 2px;

  ${({ isChecked }) =>
    isChecked &&
    css`
      border: 0;
      background: #15aedb;
    `};
`;

const CheckText = styled.div`
  ${font(14, 400, 20)}
`;

const ConfirmBtn = styled.button`
  ${font(18, 700, 27)}
  width: 100%;
  margin-top: 30px;
  padding: 12px 0;
  text-align: center;
  border-radius: 5px;
  color: white;
  background-color: #15aedb;

  :disabled {
    background-color: #999999;
  }
`;

const AttachmentWrap = styled.div`
  background-color: #fafafa;
  border-radius: 5px;
  padding: 8px 16px;

  > button:not(:last-of-type) {
    margin-bottom: 18px;
  }
`;

const AttachmentFileName = styled(CheckText.withComponent('button'))`
  text-decoration: underline;
  display: block;
`;
