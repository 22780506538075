import { ChangeEvent } from 'react';

import AddContents from './AddContents';
import EditContents from './EditContents';

interface IWriteTabProps {
  inputValue: { add: string; edit: string };
  targetId: number;
  handleAddBtnClick: () => void;
  handleEditBtnClick: () => void;
  handleTextInput: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

function WriteTab({ inputValue, targetId, handleAddBtnClick, handleEditBtnClick, handleTextInput }: IWriteTabProps) {
  const mode = !targetId ? 'add' : 'edit';

  const CONTENTS = {
    add: (
      <AddContents
        handleAddBtnClick={handleAddBtnClick}
        handleTextInput={handleTextInput}
        inputValue={inputValue.add}
      />
    ),
    edit: (
      <EditContents
        handleEditBtnClick={handleEditBtnClick}
        handleTextInput={handleTextInput}
        inputValue={inputValue.edit}
        targetId={targetId}
      />
    ),
  };

  return <>{CONTENTS[mode]}</>;
}

export default WriteTab;
