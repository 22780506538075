import { useInfiniteQuery } from '@tanstack/react-query';
import axiosClient from 'libs/axios/axios';

interface IGetOperationDetailListParams {
  pageParam: number;
  pageSize?: number;
  keyword?: string;
}

const getOperationDetailList = async (values: IGetOperationDetailListParams) => {
  const { pageParam, pageSize = 10, keyword = '' } = values;

  const { data } = await axiosClient.get(
    `/operation/detail?page_num=${pageParam}&page_size=${pageSize}&keyword=${keyword}`,
  );

  return {
    result: data.data,
    nextPage: pageParam + 1,
    isLast: !!data.next,
  };
};

const useGetOperationDetailListQuery = (keyword?: string) =>
  useInfiniteQuery(
    ['operationDetail', 'list', keyword],
    ({ pageParam = 1 }) => getOperationDetailList({ pageParam, keyword }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.isLast) return lastPage.nextPage;
        return undefined;
      },
      onError: (error) => {
        console.error('get operation detail list error', error);
      },
    },
  );

export default useGetOperationDetailListQuery;
