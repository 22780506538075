/* eslint-disable react/jsx-one-expression-per-line */

import { useState, ChangeEvent, useMemo, FormEvent } from 'react';
import styled from 'styled-components';

import useIntersection from 'hooks/useInterSect';

import { formatPhone } from 'util/format';
import { validatePhone } from 'util/regExp';

import { flex } from 'styles/flex';
import { font } from 'styles/fonts';
import { IAttendee, INewAttendee } from 'types/tbmManagement';

interface IAttendeeListPropTypes {
  query: any;
  target: IAttendee[];
  addAttendee: Function;
  showDeleteAlert: boolean;
  handleItemClick: Function;
}

interface IAddFormInput {
  key: keyof INewAttendee;
  label: '이름' | '휴대폰 번호';
}

interface IAddFormInputError {
  name: boolean;
  phone: boolean;
}

const INPUT_LIST: IAddFormInput[] = [
  { key: 'name', label: '이름' },
  { key: 'phone', label: '휴대폰 번호' },
];

const initialNewAttendee: INewAttendee = { name: '', phone: '' };
const initialInputError: IAddFormInputError = { name: false, phone: false };

function AttendeeList({ target, handleItemClick, showDeleteAlert, query, addAttendee }: IAttendeeListPropTypes) {
  const [newAttendee, setNewAttendee] = useState<INewAttendee>(initialNewAttendee);

  const [inputError, setInputError] = useState<IAddFormInputError>(initialInputError);

  const { isFetching, hasNextPage, fetchNextPage } = query;

  const { name: newAttendeeName, phone: newAttendeePhone } = newAttendee;

  const data = useMemo<IAttendee[]>(
    () => query.data?.pages.flatMap(({ result }: { result: IAttendee[] }) => result) || [],
    [query.data],
  );

  const handleAddFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!checkInputError()) return;
    addAttendee(newAttendee);
    setNewAttendee(initialNewAttendee);
  };

  const handleInput = (key: string, e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setNewAttendee((prev) => ({ ...prev, [key]: key === 'phone' ? formatPhone(value) : value }));
    setInputError((prev) => ({ ...prev, [key]: !value.length }));
  };

  // 길이, 휴대폰 형식 check 후 에러가 없으면 true 리턴
  const checkInputError = () => {
    const isLengthError = Object.values(newAttendee).some((el) => !el.length);
    const isPhoneValid = validatePhone(newAttendeePhone);
    const isError = isLengthError || !isPhoneValid;

    setInputError(
      isError
        ? {
            name: !newAttendeeName.length,
            phone: !newAttendeePhone.length || !isPhoneValid,
          }
        : initialInputError,
    );

    return !isError;
  };

  const ref = useIntersection(async (entry, observer) => {
    observer.unobserve(entry.target);
    if (hasNextPage && !isFetching) {
      fetchNextPage();
    }
  });

  return (
    <>
      <form onSubmit={handleAddFormSubmit}>
        <InputList>
          {INPUT_LIST.map((input, idx) => {
            const { key, label } = input;
            return (
              <InputWrap key={`input-${idx}`} isError={inputError[key]}>
                <Label isError={inputError[key]}>{label}</Label>
                <Input
                  maxLength={key === 'phone' ? 13 : undefined}
                  value={newAttendee[key]}
                  onChange={(e) => handleInput(key, e)}
                />
              </InputWrap>
            );
          })}
          <ErrorMsgWrap>
            {Object.values(inputError).some((el) => el === true) && (
              <ErrorMsg>이름과 휴대폰번호를 입력해주세요.</ErrorMsg>
            )}
          </ErrorMsgWrap>
        </InputList>
        <AddBtn type="submit">참석자 추가</AddBtn>
      </form>
      <InfiniteScrollContainer>
        {showDeleteAlert ? (
          <DeleteAlert>
            <DeleteAlertMsg>{target.length}명의 참석자를 삭제하시겠습니까?</DeleteAlertMsg>
          </DeleteAlert>
        ) : (
          <>
            <NameList>
              {data?.map((attendee, idx) => {
                const { id, name, phone } = attendee;
                return (
                  <Name
                    key={`item-${idx}`}
                    isActive={!!target.find((el) => el.id === id)}
                    onClick={() => handleItemClick(attendee)}
                  >
                    <p>{name}</p>
                    <p>{phone}</p>
                  </Name>
                );
              })}
            </NameList>
            <InfiniteScrollTarget ref={ref} />
          </>
        )}
      </InfiniteScrollContainer>
    </>
  );
}

export default AttendeeList;

interface IActive {
  isActive: boolean;
}

interface IError {
  isError: boolean;
}

const InputList = styled.div`
  position: relative;
`;

const InputWrap = styled.div<IError>`
  ${flex('', 'center')}
  ${font(12, 400, 14)}
  padding: 12px;
  background: #ffffff;
  border: 1px solid ${({ isError }) => (isError ? ' #EA3313' : '#c0c0c0')};
  border-radius: 5px;
  margin-bottom: 24px;
`;

const Label = styled.label<IError>`
  display: inline-block;
  color: ${({ isError }) => (isError ? ' #EA3313' : '#15aedb')};
  width: 100px;
`;

const Input = styled.input`
  display: inline-block;
  width: 100%;
`;

const ErrorMsgWrap = styled.div`
  padding-left: 12px;
  position: absolute;
  bottom: -24px;
`;

const ErrorMsg = styled.span`
  ${font(12, 400, 18)}
  color: #EA3313;
  left: 15px;
`;

const AddBtn = styled.button`
  ${font(12, 400, 14)}
  width: 100%;
  margin: 21px 0 24px;
  padding: 13px 0;
  background: #ffffff;
  border: 1px solid #15aedb;
  border-radius: 5px;
  color: #15aedb;
`;

const InfiniteScrollContainer = styled.div`
  height: 158px;
  border-radius: 5px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
    width: 0;
  }
  -ms-overflow-style: none;
`;

const InfiniteScrollTarget = styled.div`
  height: 1px;
`;

const DeleteAlert = styled.div`
  ${flex('center', 'center', 'column')}
  height: 100%;
  background: #15aedb;
  border-radius: 4px;
`;

const DeleteAlertMsg = styled.span`
  ${font(12, 400, 18)};
  color: #ffffff;
`;

const NameList = styled.div`
  ${flex('', '', 'column')}
  width: 100%;
  height: fit-content;
  gap: 10px;
`;

const Name = styled.div<IActive>`
  ${font(12, 400, 18)};
  ${flex('space-between', 'center')}
  padding: 9px 12px;
  background: ${({ isActive }) => (isActive ? '#aaecff' : '#DDDDDD')};
  border-radius: 4px;
  color: #000000;
`;
