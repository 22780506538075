import { useEffect } from 'react';
import dayjs from 'dayjs';

import useVoiceMarkQuery from './useVoiceMarkQuery';

const useVoiceMark = () => {
  const { data: voiceMarkData, mutate: postVoiceMarkQR } = useVoiceMarkQuery();

  const clearAudioBuffer = () => {
    localStorage.removeItem('audio');
  };

  const saveAudioBuffer = (audio: number[]) => {
    const prevBuffer = localStorage.getItem('audio');
    if (prevBuffer) {
      const arrayBuffer = JSON.parse(prevBuffer);
      const newBuffer = [...arrayBuffer, ...audio];
      const byteString = JSON.stringify(newBuffer);
      localStorage.setItem('audio', byteString);
      return;
    }

    localStorage.setItem('audio', JSON.stringify(audio));
  };

  const getVoiceMarkQR = async (attendeeId: number) => {
    const prevBuffer = localStorage.getItem('audio');
    const arrayBuffer = JSON.parse(prevBuffer);
    const audio = new Int16Array(arrayBuffer);
    const audioBlob = new Blob([audio.buffer], { type: 'audio/wav' });
    const audioFormData = new FormData();

    audioFormData.append('audio', audioBlob, `attendee_${attendeeId}.wav`);
    audioFormData.append('embed_string', dayjs().format('YYYY-MM-DD'));
    audioFormData.append('key', process.env.REACT_APP_VOICEMARK_KEY);

    postVoiceMarkQR(audioFormData);
  };

  useEffect(() => {
    if (voiceMarkData) {
      clearAudioBuffer();
    }
  }, [voiceMarkData]);

  return { clearAudioBuffer, getVoiceMarkQR, saveAudioBuffer, voiceMarkData };
};

export default useVoiceMark;
