import { useState, ChangeEvent } from 'react';
import styled, { css } from 'styled-components';
import { useRecoilState } from 'recoil';

import ListTab from './components/ListTab';
import WriteTab from './components/WriteTab';

import { tbmInfoState } from 'pages/recoil/TbmManagement';
import { TBM_INFO_DATA } from 'pages/TbmManagement/constant';

import { flex } from 'styles/flex';
import { font } from 'styles/fonts';

interface ISearchList {
  dataKey: string;
  query: any;
  request: {
    addRequest: Function;
    editRequest: Function;
    deleteRequest: Function;
  };
  onChangeKeyword: Function;
  onClose: Function;
}

type TabMenuList = 'list' | 'write';

export default function SearchList({ dataKey, query, request, onChangeKeyword, onClose }: ISearchList) {
  const [tbmInfo, setTbmInfo] = useRecoilState(tbmInfoState);
  const selectedItem = tbmInfo[dataKey];

  const [targetList, setTargetList] = useState(selectedItem);
  const [targetNumberList, setTargetNumberList] = useState(selectedItem.map((el) => el.id));
  const [tabMenu, setTabMenu] = useState<TabMenuList>('list');
  const [targetId, setTargetId] = useState(0);
  const [inputValue, setInputValue] = useState({ add: '', edit: '' });
  const [deleteEnable, setDeleteEnable] = useState(false);

  const { addRequest, editRequest, deleteRequest } = request;

  const handleAddBtnClick = () => {
    if (!inputValue.add) return alert('글을 작성해주세요.');
    addRequest(inputValue.add);
    setTabMenu('list');
    setInputValue({ add: '', edit: '' });
  };

  const handleCancelBtnClick = () => {
    setDeleteEnable(false);
  };

  const handleConfirmBtnClick = () => {
    setTbmInfo((prev) => ({ ...prev, [dataKey]: targetList }));
    onClose();
  };

  const handleDeleteBtnClick = () => {
    if (!deleteEnable && targetList.length) return setDeleteEnable(true);
    deleteRequest(targetNumberList);
    setDeleteEnable(false);
    setTargetList([]);
  };

  const handleEditBtnClick = () => {
    if (!inputValue.edit) return alert('글을 작성해주세요.');
    editRequest({ targetId, inputValue: inputValue.edit });
    setTabMenu('list');
    setInputValue({ add: '', edit: '' });
    setTargetId(0);
  };

  const handleItemClick = (id: number, clickedValue: string) => {
    const { value } = TBM_INFO_DATA[dataKey];

    const filteredResult = targetList.find((el) => el.id === id)
      ? targetList.filter((el) => el.id !== id)
      : targetList.concat({ id, [value]: clickedValue });

    // set numberList and full info array separately for less computation.
    setTargetList(filteredResult);
    setTargetNumberList(filteredResult.map((el) => el.id));
  };

  const handleItemEditBtnClick = (id: number, value: string) => {
    setTargetId(id);
    setInputValue((prev) => ({ ...prev, edit: value }));
    setTabMenu('write');
  };

  const handleTextInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const mode = !targetId ? 'add' : 'edit';
    setInputValue((prev) => ({ ...prev, [mode]: e.target.value }));
  };

  const handleTabMenu = (value: TabMenuList) => {
    // reset targetId when change TabMenu to write new operation.
    if (tabMenu === 'list') {
      setTargetId(0);
    }
    setTabMenu(value);
  };

  const CONTENTS = {
    write: (
      <WriteTab
        handleAddBtnClick={handleAddBtnClick}
        handleEditBtnClick={handleEditBtnClick}
        handleTextInput={handleTextInput}
        inputValue={inputValue}
        targetId={targetId}
      />
    ),
    list: (
      <ListTab
        dataKey={dataKey}
        deleteEnable={deleteEnable}
        handleCancelBtnClick={handleCancelBtnClick}
        handleConfirmBtnClick={handleConfirmBtnClick}
        handleDeleteBtnClick={handleDeleteBtnClick}
        handleItemClick={handleItemClick}
        handleItemEditBtnClick={handleItemEditBtnClick}
        query={query}
        targetNumberList={targetNumberList}
        onChangeKeyword={onChangeKeyword}
      />
    ),
  };

  return (
    <Container>
      <Header>
        <HeaderText isActive={tabMenu === 'list'} onClick={() => handleTabMenu('list')}>
          목록
        </HeaderText>
        <Line />
        <HeaderText isActive={tabMenu === 'write'} onClick={() => handleTabMenu('write')}>
          작성
        </HeaderText>
      </Header>
      {CONTENTS[tabMenu]}
    </Container>
  );
}

interface IActive {
  isActive: boolean;
}

const Container = styled.div`
  ${flex('', '', 'column')}
`;

const Header = styled.div`
  ${flex('space-evenly', 'center')}
  margin : 30px 0;
`;

const HeaderText = styled.p<IActive>`
  ${font(14, 400, 14)};
  color: #000000;
  ${({ isActive }) =>
    isActive &&
    css`
      color: #15aedb;
      font-weight: 700;
    `}
`;

const Line = styled.div`
  width: 1px;
  height: 12px;
  background: #d9d9d9;
`;
