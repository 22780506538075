import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';

import axiosClient from 'libs/axios/axios';
import { snakeToCamel } from 'util/snakeToCamel';

import { IAnnouncementDetail } from 'types/announcement';

const fetchAnnouncementDetail = async ({ detailId, category }: { detailId: number; category: string }) => {
  const { data } = await axiosClient.get(`/announcement/employee/${detailId}?category=${category}`);

  return data;
};
export const useAnnouncementDetailQuery = (id: number, category: string) =>
  useQuery<IAnnouncementDetail, AxiosError>(
    ['Announcement', 'detail', id],
    () => fetchAnnouncementDetail({ detailId: id, category }),
    {
      select: (data) => snakeToCamel(data),
      onError: (error) => {
        console.error('Announcement Detail error', error);
      },
      enabled: !!id,
    },
  );
