import { ReactNode } from 'react';

import ReactModal from 'react-modal';

interface ReactModalProps {
  bgColor?: string;
  children: ReactNode;
  isOpen: boolean;
  overflow?: string;
  closeModal: () => void;
}

export default function Modal({ children, isOpen, overflow, closeModal, bgColor }: ReactModalProps) {
  return (
    <ReactModal
      shouldCloseOnOverlayClick
      ariaHideApp={false}
      isOpen={isOpen}
      style={customStyles(bgColor, overflow)}
      onRequestClose={() => closeModal()}
    >
      {children}
    </ReactModal>
  );
}

const customStyles = (bgColor?: string, overflow?: string) => ({
  overlay: {
    backgroundColor: bgColor || 'rgba(0,0,0,0.07)',
    zIndex: 2,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    padding: 0,
    transform: 'translate(-50%, -50%)',
    overflow,
    borderRadius: '10px',
  },
});
