import { useEffect, useState } from 'react';

interface IUseDebouncePropTypes {
  value: any;
  wait?: number;
}

// value가 변경되면 기존 timer를 삭제합니다.
const useDebounce = ({ value, wait = 500 }: IUseDebouncePropTypes) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), wait);

    return () => {
      clearTimeout(timer);
    };
  }, [value, wait]);

  return debouncedValue;
};

export default useDebounce;
