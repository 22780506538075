import dayjs, { Dayjs } from 'dayjs';

// 2022-10-10 => { month: 10, date: 10, day: '월'}
export const formatDate = (targetValue: string) => {
  const targetDate = dayjs(targetValue);
  const dayList = ['일', '월', '화', '수', '목', '금', '토'];

  const day = dayList[targetDate.get('day')];
  const month = targetDate.get('month') + 1;
  const date = targetDate.get('date');

  return { month, date, day };
};

// dayjs('2022-10-10'), dayjs('2022-10-18') => true
// dayjs('2022-10-10T10:00:00'), dayjs() => 현재 시간이 더 뒤일 경우 true
export const checkIsBefore = (base: Dayjs, target: Dayjs) => base.isBefore(target);
