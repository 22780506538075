import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import axiosClient from 'libs/axios/axios';

import { snakeToCamel } from 'util/snakeToCamel';

import { IOrganizationChartList } from 'types/announcement';

const fetchGetOrganizationChart = async () => {
  const { data } = await axiosClient.get('/organization-chart');

  return data;
};

export const useGetOrganizationChartQuery = () =>
  useQuery<IOrganizationChartList, AxiosError>(['Announcement', 'chart'], fetchGetOrganizationChart, {
    select: (data) => snakeToCamel(data),
    onError: (error) => {
      console.error('get chart error', error);
    },
  });
