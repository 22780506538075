import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import useRNListener from 'hooks/useRNListener';

import Announcement from 'pages/Announcement';
import AnnounceDetail from 'pages/Announcement/components/AnnounceDetail';
import TbmManagement from 'pages/TbmManagement';
import CreateTBM from 'pages/CreateTBM';
import EmployeeResetPassword from 'pages/EmployeeResetPassword';
import SelfAuthentication from 'pages/SelfAuthentication';
import SelfAuthenticationResult from 'pages/SelfAuthenticationResult';
import Recording from 'pages/Recording';
import AttendeeSafetyRuleList from 'pages/Attendee/AttendeeSafetyRuleList';
import AttendeeTbm from 'pages/Attendee/AttendeeTbm';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

const queryClient = new QueryClient();

export default function App() {
  useRNListener();

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen />
        <Routes>
          <Route element={<Announcement />} path="announcement" />
          <Route element={<AnnounceDetail />} path="announcement/detail/:id" />
          <Route element={<EmployeeResetPassword />} path="employee-reset-password" />
          <Route element={<CreateTBM />} path="tbm" />
          <Route element={<TbmManagement />} path="tbm/management/:userName/:date/:id" />
          <Route element={<SelfAuthentication />} path="self_authentication" />
          <Route element={<SelfAuthenticationResult />} path="self_authentication/result" />
          <Route element={<Recording />} path="recording" />
          <Route element={<AttendeeSafetyRuleList />} path="attendee/:attendeeId/safety-rule" />
          <Route element={<AttendeeTbm />} path="attendee/:attendeeId/tbm/:tbmId" />
        </Routes>
      </QueryClientProvider>
    </BrowserRouter>
  );
}
