export default function useFileDownload() {
  const download = (fileName: string, filePath: string) => {
    const link = document.createElement('a');
    link.href = filePath;
    link.download = fileName;
    link.click();
  };

  return { download };
}
