import { useMemo } from 'react';
import styled from 'styled-components';

import { formatDate } from 'util/date';

import { flex } from 'styles/flex';
import { font } from 'styles/fonts';

interface IResultMessage {
  data: {
    date: string;
    time: string;
    totalParticipantCount: number;
  };
  handleSave: Function;
  onClose: Function;
}

function ResultMessage({ data, onClose, handleSave }: IResultMessage) {
  const { date, time, totalParticipantCount } = data;

  const sendingTime = useMemo(() => {
    const { month, date: formattedDate, day } = formatDate(date);
    const result = `${month}월 ${formattedDate}일 (${day}) ${time}`;
    return result;
  }, [date, time]);

  return (
    <Container>
      <TextWrap>
        <SendingInfo>
          <div>{`${totalParticipantCount}명에게 ${sendingTime} `}</div>
          <div>안전미팅을 전송합니다.</div>
        </SendingInfo>
        <div>* 안전미팅 시간 5분 전에 전송합니다.</div>
      </TextWrap>

      {/* {추후 에러시 보여줄 메세지} */}
      <BtnWrap>
        <CancelBtn onClick={() => onClose()}>취소</CancelBtn>
        <ConfirmBtn onClick={() => handleSave()}>확인</ConfirmBtn>
      </BtnWrap>
    </Container>
  );
}

export default ResultMessage;

const Container = styled.div`
  ${flex('', 'center', 'column')}
`;

const TextWrap = styled.div`
  ${flex('', 'center', 'column')}
  margin : 30px 0;
  gap: 10px;

  div {
    ${font(14, 400, 27)}
  }
`;

const SendingInfo = styled.div`
  ${flex('', 'center', 'column')}
`;

const BtnWrap = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  gap: 10px;
`;

const ConfirmBtn = styled.button`
  ${font(18, 500, 17)}
  ${flex('center', 'center')}
  height: 52px;
  flex: 1;
  border-radius: 5px;
  text-align: center;
  background: #15aedb;
  color: #ffffff;
`;

const CancelBtn = styled(ConfirmBtn)`
  background: #999999;
`;
