import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import axiosClient from 'libs/axios/axios';

import { snakeToCamel } from 'util/snakeToCamel';
import { ISafetyRuleList } from 'types/attendeeTbm';

const getSafetyRuleList = async (attendeeId: number, queryString: string) => {
  const { data } = await axiosClient.get(`/attendee/${attendeeId}/safety-rule${queryString}`);

  const cameled = snakeToCamel(data);
  return cameled;
};

export const useGetSafetyRuleList = (attendeeId: number, queryString: string) =>
  useQuery<ISafetyRuleList, AxiosError>(
    ['attendee', 'safetyRuleList'],
    () => getSafetyRuleList(attendeeId, queryString),
    {
      onError: (error: AxiosError) => {
        console.error(`get safetyRuleList error ${error}`);
      },
    },
  );
