import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import useIntersection from 'hooks/useInterSect';
import useDebounce from 'hooks/useDebounce';

import { flex } from 'styles/flex';
import { font } from 'styles/fonts';
import { IParticipant } from 'types/tbmManagement';
import { useSetRecoilState } from 'recoil';
import { tbmSearchKeywordState } from 'pages/recoil/TbmManagement';

interface IParticipantListProps {
  query: any;
  target: IParticipant[];
  handleItemClick: Function;
}

function ParticipantList({ query, target, handleItemClick }: IParticipantListProps) {
  const setSearchKeyword = useSetRecoilState(tbmSearchKeywordState);
  const [inputValue, setInputValue] = useState('');
  const debounceInputValue = useDebounce({ value: inputValue });

  const { isLoading, isFetching, hasNextPage, fetchNextPage } = query;

  const data = useMemo<IParticipant[]>(
    () => query.data?.pages.flatMap(({ result }: { result: IParticipant[] }) => result) || [],
    [query.data],
  );

  const ref = useIntersection(async (entry, observer) => {
    observer.unobserve(entry.target);
    if (hasNextPage && !isFetching) {
      fetchNextPage();
    }
  });

  const handleInputValue = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    setSearchKeyword((prev: any) => ({ ...prev, participantList: debounceInputValue }));
  }, [debounceInputValue]);

  if (isLoading) return <div>Loading...</div>;

  return (
    <>
      <Input>
        <InputField placeholder="검색해주세요." onChange={handleInputValue} />
      </Input>
      <Contents>
        <InfiniteScrollContainer>
          <NameList>
            {data?.map((participant, idx) => (
              <Name
                key={`participant-${idx}`}
                isActive={!!target.find((el) => el.id === participant.id)}
                onClick={() => handleItemClick(participant)}
              >
                <p>{participant.name}</p>
                <p>{participant.teamName}</p>
              </Name>
            ))}
          </NameList>
          <InfiniteScrollTarget ref={ref} />
        </InfiniteScrollContainer>
      </Contents>
    </>
  );
}

export default ParticipantList;

interface IActive {
  isActive: boolean;
}

const Input = styled.div`
  background: #ffffff;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  height: 42px;
  margin-bottom: 24px;
  padding: 12px;
  position: relative;
`;

const InputField = styled.input`
  border: none;
  ${font(12, 400, 18)}
  width: 100%;
  height: 100%;
  ::placeholder {
    color: #999999;
  }
`;

const Contents = styled.div`
  height: 311px;
`;

const NameList = styled.div`
  ${flex('', '', 'column')}
  width: 100%;
  height: fit-content;
  gap: 10px;
`;

const Name = styled.div<IActive>`
  ${font(12, 400, 18)};
  ${flex('space-between', 'center')}
  padding: 9px 12px;
  background: ${({ isActive }) => (isActive ? '#aaecff' : '#DDDDDD')};
  border-radius: 4px;
  color: #000000;
`;

const InfiniteScrollContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

const InfiniteScrollTarget = styled.div`
  height: 1px;
`;
