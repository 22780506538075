import { IInitialErrorType } from 'types/tbmManagement';

export const initialError: IInitialErrorType = {
  author: false,
  time: false,
  location: false,
  operationType: false,
  operationDetail: false,
  safetyMeasure: false,
  safetyRule: false,
  emergencyProcedure: false,
  totalParticipant: false,
};
