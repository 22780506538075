import { useInfiniteQuery } from '@tanstack/react-query';
import axiosClient from 'libs/axios/axios';

interface IGetOperationTypeParams {
  pageParam: number;
  pageSize?: number;
  keyword?: string;
}

const getOperationType = async (values: IGetOperationTypeParams) => {
  const { pageParam, pageSize = 10, keyword = '' } = values;

  const { data } = await axiosClient.get(`/operation?page_num=${pageParam}&page_size=${pageSize}&keyword=${keyword}`);

  return {
    result: data.data,
    nextPage: pageParam + 1,
    isLast: !!data.next,
  };
};
export const useGetOperationTypeQuery = () =>
useInfiniteQuery(['operation', 'type'], ({ pageParam = 1 }) => getOperationType({ pageParam }), {
    getNextPageParam: (lastPage) => {
      if (lastPage.isLast) return lastPage.nextPage;
      return undefined;
    },
    onError: (error) => {
      console.error('get operationType list error', error);
    },
  });
