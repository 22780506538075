const mobilePasswordCheck = (password: string) => {
  const passwordReg = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,15}$/;

  return passwordReg.test(password);
};

const validatePhone = (phone: string) => {
  const phoneReg = /^010-?[0-9]{4}-?[0-9]{4}$/;

  return phoneReg.test(phone);
};

export { mobilePasswordCheck, validatePhone };
