import styled from 'styled-components';

import Modal from 'pages/components/Modal';
import { flex } from 'styles/flex';
import { font } from 'styles/fonts';

type ErrorModalProps = {
  modalVisible: boolean;
  quitBtnText: string;
  confirmBtnText: string;
  modalText: string;
  modalBoldText?: string;
  closeModal: () => void;
  handleRequest: () => void;
};

export default function ErrorModal({
  modalVisible,
  quitBtnText,
  confirmBtnText,
  modalText,
  modalBoldText,
  closeModal,
  handleRequest,
}:ErrorModalProps) {
  const confirmRequest = () => {
    handleRequest();
    closeModal();
  };

  return (
    <Modal closeModal={closeModal} isOpen={modalVisible}>
      <ModalContainerCenteredView>
        <ModalContainer>
          <ModalTextWrap>
            {modalText}
            <ModalBoldText>{modalBoldText}</ModalBoldText>
          </ModalTextWrap>
          <ModalBtnWrap>
            <ModalBtnGrey onClick={closeModal}>
              <ModalBtnText>{quitBtnText}</ModalBtnText>
            </ModalBtnGrey>
            <ModalBtnGreen onClick={confirmRequest}>
              <ModalBtnText>{confirmBtnText}</ModalBtnText>
            </ModalBtnGreen>
          </ModalBtnWrap>
        </ModalContainer>
      </ModalContainerCenteredView>
    </Modal>
  );
}

const ModalContainerCenteredView = styled.div`
  ${flex('center', 'center', 'column')};
  flex: 1;
`;

const ModalContainer = styled.div`
  ${flex('space-between', 'center', 'column')};
  width: 330px;
  background-color: white;
  border-radius: 10px;
  padding: 38px 30px 30px;
`;

const ModalTextWrap = styled.div`
  ${flex('', 'center', 'column')};
  padding: 4px;
  width: 289px;
  margin-bottom: 30px;
  ${font(14, 400, 27)};
  color: #999999;
  text-align: center;
`;

const ModalBoldText = styled.div`
  color: #000000;
`;

const ModalBtnWrap = styled.div`
  ${flex('space-between', 'center', 'row')};
  width: 100%;
`;

const ModalBtnGrey = styled.button`
  ${flex('center', 'center')}
  width: 130px;
  height: 52px;
  border-radius: 4px;
  background-color: #cdcccc;
`;

const ModalBtnGreen = styled(ModalBtnGrey)`
  background-color: #15aedb;
`;

const ModalBtnText = styled.div`
  ${font(18, 700, 27)}
  text-align: center;
  color: white;
`;
