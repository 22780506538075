import { ChangeEvent } from 'react';
import styled from 'styled-components';

import { flex } from 'styles/flex';
import { font } from 'styles/fonts';

interface IEditContentsProps {
  inputValue: string;
  targetId: number;
  handleEditBtnClick: () => void;
  handleTextInput: (e: ChangeEvent) => void;
}

function EditContents({ inputValue, targetId, handleEditBtnClick, handleTextInput }: IEditContentsProps) {
  return (
    <>
      <TextArea placeholder="글을 작성해주세요." value={targetId ? inputValue : ''} onChange={handleTextInput} />
      <BtnList>
        <BtnWrap>
          <EditBtn onClick={() => handleEditBtnClick()}>저장</EditBtn>
        </BtnWrap>
      </BtnList>
    </>
  );
}

export default EditContents;

const TextArea = styled.textarea`
  ${font(12, 400, 18)}
  width: 100%;
  height: calc(202px + 66px);
  padding: 12px;
  margin-bottom: 30px;
  background: #ffffff;
  border: 1px solid #c0c0c0;
  border-radius: 5px;

  ::placeholder {
    color: #999999;
  }
`;

const BtnList = styled.div`
  ${flex('', 'center')}
  flex: 1;
  gap: 10px;
`;

const BtnWrap = styled.div`
  display: flex;
  flex: 1;
`;

const EditBtn = styled.button`
  ${font(18, 500, 17)}
  ${flex('center', 'center')}
  height: 52px;
  flex: 1;
  border-radius: 5px;
  text-align: center;
  background: #15aedb;
  color: #ffffff;

  :disabled {
    background: #999999;
  }
`;
