import { useSetRecoilState } from 'recoil';
import { fontScaleState } from 'pages/recoil/fonts';

const useRNListener = () => {
  const setFontScale = useSetRecoilState(fontScaleState);

  const listener = (event) => {
    const { data, type } = JSON.parse(event.data);
    const fontScale = localStorage.getItem('fontScale');

    if (type === 'token') {
      localStorage.setItem('token', data);
    }

    if (type === 'fontScale' && data !== fontScale) {
      localStorage.setItem('fontScale', data);
      setFontScale(+data);
    }
  };

  if (window.ReactNativeWebView) {
    /** android */
    document.addEventListener('message', listener);
    /** ios */
    window.addEventListener('message', listener);
  }
};

export default useRNListener;
