import { useMutation, useQueryClient } from '@tanstack/react-query';
import axiosClient from 'libs/axios/axios';

const deleteSafetyMeasure = async (idList: number[]) => {
  const { data } = await axiosClient.post('/safety-measure/delete', {
    id_list: idList,
  });

  return data;
};

const useDeleteSafetyMeasureQuery = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteSafetyMeasure, {
    onSuccess: () => {
      queryClient.invalidateQueries(['safetyMeasure', 'list']);
    },
    onError: (error) => {
      alert('삭제 실패. 다시 한 번 시도해주세요');
      console.error('delete safetyMeasure error', error);
    },
  });
};

export default useDeleteSafetyMeasureQuery;
