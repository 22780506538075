import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { flex } from 'styles/flex';
import { font } from 'styles/fonts';
import { fitImg } from 'styles/mixins';

interface IHeaderPropTypes {
  title: string;
  showPrevBtn?: boolean;
}

export default function Header({ title, showPrevBtn }: IHeaderPropTypes) {
  const navigate = useNavigate();

  return (
    <HeaderContainer>
      {showPrevBtn ? (
        <BtnWrap onClick={() => navigate(-1)}>
          <img alt="prev button" src="/icons/ic-prev-black.png" />
        </BtnWrap>
      ) : (
        <BtnWrap />
      )}
      <Title>{title}</Title>
      <BtnWrap />
    </HeaderContainer>
  );
}

const HeaderContainer = styled.div`
  ${flex('space-between', 'center')}
  margin-bottom: 35px;
`;

const BtnWrap = styled.button`
  ${fitImg}
  width: 32px;
  height: 31px;
`;

const Title = styled.div`
  ${font(20, 700, 21)}
  height: 100%;
  vertical-align: center;
  text-align: center;
`;
