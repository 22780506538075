import { useMutation, useQueryClient } from '@tanstack/react-query';

import axiosClient from 'libs/axios/axios';

const patchOperationType = async (operationType: string) => {
  const { data } = await axiosClient.post('/operation', {
    name: operationType,
  });

  return data.data;
};
export const useAddOperationTypeQuery = () => {
  const queryClient = useQueryClient();

  return useMutation((operationType: string) => patchOperationType(operationType), {
    onSuccess: () => {
      queryClient.invalidateQueries(['operation', 'type']);
    },
    onError: (error) => {
      console.error('add operation type error', error);
    },
  });
};
