import { useMutation, useQueryClient } from '@tanstack/react-query';

import { INewAttendee } from 'types/tbmManagement';
import axiosClient from 'libs/axios/axios';

interface IAddAttendeeRequestBody {
  name: string;
  phone: string;
}

const addAttendee = async (requestBody: IAddAttendeeRequestBody) => {
  const { name, phone } = requestBody;

  const { data } = await axiosClient.post('/attendee', {
    name,
    phone,
  });

  return data;
};

const useAddAttendeeQuery = () => {
  const queryClient = useQueryClient();

  return useMutation((values: INewAttendee) => addAttendee(values), {
    onSuccess: () => {
      // ['participant', 'attendeeList'] 로 시작하는 쿼리 모두 무효화
      alert('추가 성공');
      queryClient.invalidateQueries(['participant', 'attendeeList']);
    },
    onError: (error) => {
      alert('추가 실패. 다시 한 번 시도해주세요');
      console.error('add attendee error', error);
    },
  });
};

export default useAddAttendeeQuery;
