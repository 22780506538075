import axios, { AxiosRequestConfig, AxiosError } from 'axios';

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_ADDRESS,
  timeout: 8000,
  headers: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Content-type': 'application/json',
  },
});

axiosClient.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (config.headers === undefined) {
      config.headers = {};
    }

    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = token;
    }

    return config;
  },
  (error: AxiosError) => Promise.reject(error),
);

export default axiosClient;
