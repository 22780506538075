import { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import dayjs, { Dayjs } from 'dayjs';
import { useRecoilState } from 'recoil';

import { useTBMByDateQuery } from 'pages/CreateTBM/queries/useTBMByDateQuery';
import { dateRangeState, selectedDateState } from 'pages/recoil/createTBM';

import { flex } from 'styles/flex';
import { font } from 'styles/fonts';
import { fitImg } from 'styles/mixins';

interface ICalendarPropTypes {
  dayArray: string[];
  handleModal: () => void;
  postMessageToRN: (selectedDate: string, id: number | null, idType: string) => void;
}

export default function Calendar({ dayArray, handleModal, postMessageToRN }: ICalendarPropTypes) {
  const { data: tbmList } = useTBMByDateQuery();
  const todayRef = useRef(dayjs().startOf('day'));
  const [selectedDate, setSelectedDate] = useRecoilState(selectedDateState);
  const [dateRange, setDateRange] = useRecoilState(dateRangeState);
  const [baseDateForWeekArray, setBaseDateForWeekArray] = useState<Dayjs>(selectedDate);
  const [dateArray, setDateArray] = useState<{ date: number; isPrevMonth: boolean }[]>([]);
  const [dataDateArray, setDataDateArray] = useState<number[]>();

  const { startDate, endDate } = dateRange;

  const createWeekArray = () => {
    const weekArray = [];
    const startDateOfWeek = baseDateForWeekArray.startOf('week');
    const endDateOfWeek = baseDateForWeekArray.endOf('week');
    const prevMonthEndDate = startDateOfWeek.endOf('month').date();
    setDateRange({ startDate: startDateOfWeek, endDate: endDateOfWeek });

    if (endDateOfWeek.date() < startDateOfWeek.date()) {
      for (let i = startDateOfWeek.date(); i <= prevMonthEndDate; i++) {
        weekArray.push({ date: i, isPrevMonth: true });
      }
      for (let i = 1; i <= endDateOfWeek.date(); i++) {
        weekArray.push({ date: i, isPrevMonth: false });
      }
      setDateArray(weekArray);
      return;
    }

    for (let i = startDateOfWeek.date(); i <= endDateOfWeek.date(); i++) {
      weekArray.push({ date: i, isPrevMonth: false });
    }
    setDateArray(weekArray);
  };

  const dateText = useMemo(
    () =>
      selectedDate.isBefore(startDate) || selectedDate.isAfter(endDate)
        ? `${baseDateForWeekArray.year()}년 ${baseDateForWeekArray.month() + 1}월`
        : `${selectedDate.year()}년 ${selectedDate.month() + 1}월 ${selectedDate.date()}일`,
    [endDate, startDate, selectedDate],
  );

  const handleDateClick = (date: number, isPrevMonth: boolean) => {
    const month = isPrevMonth ? startDate.get('month') + 1 : endDate.get('month') + 1;
    const year = isPrevMonth ? startDate.get('year') : endDate.get('year');
    const newDate = `${year}-${month}-${date}`;
    setSelectedDate(dayjs(newDate));
  };

  const handleArrowBtnClick = (type: string) => {
    if (type === 'add') {
      setBaseDateForWeekArray(endDate.add(1, 'week'));
      return;
    }

    if (type === 'subtract') {
      setBaseDateForWeekArray(endDate.subtract(1, 'week'));
    }
  };

  const handleTemplateBtnClick = () => {
    if (selectedDate.isBefore(todayRef.current)) return;
    handleModal();
  };

  const handleCreateNewTbmBtnClick = () => {
    if (selectedDate.isBefore(todayRef.current)) return;
    postMessageToRN(dateText, null, 'template');
  };

  useEffect(() => {
    createWeekArray();
  }, [baseDateForWeekArray]);

  useEffect(() => {
    setDataDateArray(tbmList?.map((item) => dayjs(item.date).date()));
  }, [tbmList]);

  return (
    <CalendarContainer>
      <TitleWrap>
        <DateBtn onClick={() => handleArrowBtnClick('subtract')}>
          <img alt="prev button" src="/icons/ic-prev.png" />
        </DateBtn>
        <DateText>{dateText}</DateText>
        <DateBtn onClick={() => handleArrowBtnClick('add')}>
          <img alt="next button" src="/icons/ic-next.png" />
        </DateBtn>
      </TitleWrap>
      <CalendarWrap>
        <DayWrap>
          {dayArray.map((day, index: number) => (
            <Day key={`day-${index}`}>{day}</Day>
          ))}
        </DayWrap>
        <DateWrap>
          {dateArray.map(({ date, isPrevMonth }, index) => (
            <DateAndDotWrap key={`date-${index}`}>
              <Date
                isPrevMonth={isPrevMonth}
                selected={
                  selectedDate.date() === date && !selectedDate.isBefore(startDate) && !selectedDate.isAfter(endDate)
                }
                onClick={() => handleDateClick(date, isPrevMonth)}
              >
                {date}
              </Date>
              <Dot showDot={dataDateArray?.includes(date)} />
            </DateAndDotWrap>
          ))}
        </DateWrap>
      </CalendarWrap>
      <BtnWrap>
        <TemplateBtn onClick={handleTemplateBtnClick}>템플릿 불러오기</TemplateBtn>
        <CreateBtn disabled={selectedDate.isBefore(todayRef.current)} onClick={handleCreateNewTbmBtnClick}>
          신규생성
        </CreateBtn>
      </BtnWrap>
    </CalendarContainer>
  );
}

interface IDate {
  selected: boolean;
  isPrevMonth: boolean;
}

const CalendarContainer = styled.div`
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 22px 30px 30px;
`;

const TitleWrap = styled.div`
  ${flex('center', 'center')}
  gap: 18px;
`;

const DateBtn = styled.button`
  ${fitImg}
  width: 8px;
  height: 15px;
`;

const DateText = styled.span`
  ${font(14, 700, 16)}
  width: 120px;
  text-align: center;
`;

const CalendarWrap = styled.div`
  margin-top: 31px;
`;

const DayWrap = styled.div`
  ${flex('space-between', '')}
`;

const Day = styled.span`
  ${font(12, 400, 14)}
  width: 26px;
  text-align: center;
  color: #999999;
`;

const DateWrap = styled.div`
  margin-top: 18px;
  ${flex('space-between', '')}
`;

const DateAndDotWrap = styled.div`
  ${flex('center', 'center', 'column')}
`;

const Date = styled.div<IDate>`
  ${font(12, 400, 14)}
  width: 26px;
  padding: 6px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;

  ${({ isPrevMonth }) =>
    isPrevMonth &&
    css`
      color: #999999;
    `};

  ${({ selected }) =>
    selected &&
    css`
      background-color: #15aedb;
      color: white;
    `}
`;

const Dot = styled.div<{ showDot: boolean }>`
  margin-top: 2px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #aaaaaa;
  visibility: ${({ showDot }) => (showDot ? 'visible' : 'hidden')};
`;

const BtnWrap = styled.div`
  ${flex('center', 'center')}
  margin-top: 18px;
`;

const TemplateBtn = styled.button`
  ${font(14, 700, 20)}
  width: 100%;
  padding: 11px 0;
  border: 1px solid #15aedb;
  border-radius: 5px;
  text-align: center;
  color: #15aedb;
  background-color: white;
  margin-right: 10px;
`;

const CreateBtn = styled(TemplateBtn)`
  background-color: #15aedb;
  color: white;
  margin-right: 0;

  :disabled {
    border: 1px solid #999999;
    background-color: #999999;
  }
`;
