import { useInfiniteQuery } from '@tanstack/react-query';

import axiosClient from 'libs/axios/axios';
import { snakeToCamel } from 'util/snakeToCamel';

interface IGetSafetyMeasureParams {
  pageParam: number;
  pageSize?: number;
  keyword?: string;
}

const getSafetyMeasureList = async (values: IGetSafetyMeasureParams) => {
  const { pageParam, pageSize = 10, keyword = '' } = values;

  const { data } = await axiosClient.get(
    `/safety-measure?page_num=${pageParam}&page_size=${pageSize}&keyword=${keyword}`,
  );

  const cameled = snakeToCamel(data.data);

  return {
    result: cameled.map((el) => ({ id: el.id, safetyMeasureName: el.safetyMeasureName })),
    nextPage: pageParam + 1,
    isLast: !!data.next,
  };
};

const useGetSafetyMeasureListQuery = (keyword?: string) =>
  useInfiniteQuery(
    ['safetyMeasure', 'list', keyword],
    ({ pageParam = 1 }) => getSafetyMeasureList({ pageParam, keyword }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.isLast) return lastPage.nextPage;
        return undefined;
      },
      onError: (error) => {
        console.error('get safetyMeasure list error', error);
      },
    },
  );

export default useGetSafetyMeasureListQuery;
