import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import axiosClient from 'libs/axios/axios';
import { IAttendeeTbm } from 'types/attendeeTbm';

import { snakeToCamel } from 'util/snakeToCamel';

// queryString 예시 : 문자로 전송받은 ?phone=010-2312-1231&date=2022-10-26가 그대로 들어감
const getAttendeeTbm = async (tbmId: string, attendeeId: string, queryString: string) => {
  const { data } = await axiosClient.get(`/attendee/${attendeeId}/tbm/${tbmId}${queryString}`);

  const cameled = snakeToCamel(data);
  return cameled;
};

export const useGetAttendeeTbm = (tbmId: string, attendeeId: string, queryString: string) =>
  useQuery<IAttendeeTbm, AxiosError>(['attendee', 'tbm'], () => getAttendeeTbm(tbmId, attendeeId, queryString), {
    onError: (error: AxiosError) => {
      console.error(`get AttendeeTbm error ${error}`);
      alert('만료된 안전미팅입니다.');
    },
    retry: 1,
  });
