export const setSession = (key: string, value: any, expirationInMin: number) => {
  const expirationDate = new Date(new Date().getTime() + 60000 * expirationInMin);
  const newValue = {
    value,
    expirationDate: expirationDate.toISOString(),
  };
  window.sessionStorage.setItem(key, JSON.stringify(newValue));
};

export const getSession = (key: string) => {
  const stringValue = window.sessionStorage.getItem(key);
  if (stringValue !== null) {
    const value = JSON.parse(stringValue);
    const expirationDate = new Date(value.expirationDate);
    if (expirationDate > new Date()) {
      return value.value;
    }
    window.sessionStorage.removeItem(key);
  }
  return null;
};
