import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, menu, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    main, menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
/* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure,
    footer, header, hgroup, main, menu, nav, section {
        display: block;
    }
/* HTML5 hidden-attribute fix for newer browsers */
    *[hidden] {
        display: none;
    }

    body {
        line-height: 1;
    }

    menu, ol, ul {
        list-style: none;
    }

    blockquote, q {
        quotes: none;
    }

    blockquote:before, blockquote:after,
    q:before, q:after {
    content: '';
    content: none;
    }

    table {
         border-collapse: collapse;
         border-spacing: 0;
    }

    * {
        box-sizing: border-box;
    }

    body {
        width: 100vw;
        height: 100vh;
        padding: 0;
        margin: 0;
        font-family: 'NanumSquare';
    };

    #root {
        width: 100%;
        height: 100%;
    }

    button,
    input {
        outline: none;
        border: none;
        background: none;
        font-family: inherit;
        padding: 0;
    }

    textarea{
        resize: none;
    }

    button,
    label {
        cursor: pointer;
    };

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
    }

    ol,
    ul {
        list-style: none;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
    }


    @font-face {
        font-family: 'NanumSquare';
        font-weight: 400;
        font-style: normal;
        src: url('assets/fonts/NanumSquare-Medium.woff2') format('woff2'),
    }

    @font-face {
        font-family: 'NanumSquare';
        font-weight: 700;
        font-style: normal;
        src: url('assets/fonts/NanumSquare-Bold.woff2') format('woff2'),
    }
`;

export default GlobalStyle;
