import { useMutation, useQueryClient } from '@tanstack/react-query';
import axiosClient from 'libs/axios/axios';

const deleteEmergencyProcedure = async (idList: number[]) => {
  const { data } = await axiosClient.post('/emergency-procedure/delete', {
    id_list: idList,
  });

  return data;
};

const useDeleteEmergencyProcedureQuery = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteEmergencyProcedure, {
    onSuccess: () => {
      queryClient.invalidateQueries(['emergencyProcedure', 'list']);
    },
    onError: (error) => {
      alert('삭제 실패. 다시 한 번 시도해주세요');
      console.error('delete emergencyProcedure error', error);
    },
  });
};

export default useDeleteEmergencyProcedureQuery;
