import { useMutation } from '@tanstack/react-query';
import axiosClient from 'libs/axios/axios';
import { ITbmJoinRequestType } from 'types/recording';

const fetchTbmJoin = async ({ tbmId, isComplete, attendeeId, date, qrPath }: ITbmJoinRequestType) => {
  const { data } = await axiosClient.patch(`attendee/tbm/${tbmId}/status`, {
    attendee_id: attendeeId,
    is_complete: isComplete,
    date,
    qr_path: qrPath,
  });

  return data;
};

export const useCompleteTbmQuery = () =>
  useMutation(['TBM', 'join'], fetchTbmJoin, {
    onError: (error) => {
      console.error(error);
      alert('낭독상태 변경에 실패하였습니다. 관리자에게 문의하세요');
    },
  });
