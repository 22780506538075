import { useMutation, useQueryClient } from '@tanstack/react-query';
import axiosClient from 'libs/axios/axios';

const addSafetyRule = async (safetyRule: string) => {
  const { data } = await axiosClient.post('/safety-rule', {
    script: safetyRule,
  });

  return data;
};

const useAddSafetyRuleQuery = () => {
  const queryClient = useQueryClient();

  return useMutation(['safetyRule', 'add'], addSafetyRule, {
    onSuccess: () => {
      queryClient.invalidateQueries(['safetyRule', 'list']);
    },
    onError: (error) => {
      alert('추가 실패. 다시 한 번 시도해주세요');
      console.error('add safetyRule error', error);
    },
  });
};

export default useAddSafetyRuleQuery;
