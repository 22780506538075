/* eslint-disable react/jsx-one-expression-per-line */
import { useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import dayjs, { Dayjs } from 'dayjs';

import { useGetSafetyRuleList } from './queries/useGetSafetyRuleList';
import { useGetAttendeeInfo } from './queries/useGetAttendeeInfo';
import Header from 'pages/components/Header';

import { flex } from 'styles/flex';
import { font } from 'styles/fonts';

export default function AttendeeSafetyRuleList() {
  const location = useLocation();
  const navigate = useNavigate();
  const { attendeeId } = useParams();

  const { data: attendeeInfo } = useGetAttendeeInfo(+attendeeId, location.search);
  const { data } = useGetSafetyRuleList(+attendeeId, location.search);

  const params = useRef(new URLSearchParams(location.search));
  const dateRef = useRef<Dayjs>(dayjs(params.current.get('date')));
  const tbmIdRef = useRef<string>(params.current.get('tbm_id'));
  const phoneRef = useRef<string>(params.current.get('phone'));

  const handleStartRecording = () => {
    const hasIncomplete = data?.safetyRuleList.find((safetyRule) => !safetyRule.completed);
    if (hasIncomplete) {
      // eslint-disable-next-line max-len
      navigate(
        `/recording?tbm_id=${tbmIdRef.current}&date=${dayjs(dateRef.current).format(
          'YYYY-MM-DD',
        )}&attendeeId=${attendeeId}`,
      );
      return;
    }

    alert('오늘의 낭독수칙을 모두 낭독했습니다');
  };

  return (
    <Container>
      <Header showPrevBtn title="오늘의 안전수칙" />
      <InfoBox>
        <InfoItem>
          이름
          <span>{attendeeInfo?.name}</span>
        </InfoItem>
        <InfoItem>
          휴대폰 번호
          <span>{phoneRef.current}</span>
        </InfoItem>
      </InfoBox>
      <SafetyRuleWrap>
        <Date>
          {dateRef.current.year()}년 {dateRef.current.format('MM')}월 {dateRef.current.format('DD')}일
        </Date>
        <SafetyRuleListWrap>
          {data?.safetyRuleList.map(({ id, completed, script }, idx) => (
            <SafetyRule key={id} active={completed}>
              <div>{idx + 1}.</div>
              <div>{script}</div>
            </SafetyRule>
          ))}
        </SafetyRuleListWrap>
      </SafetyRuleWrap>
      <StartRecordingBtn onClick={handleStartRecording}>낭독 시작</StartRecordingBtn>
    </Container>
  );
}

type IActive = {
  active: boolean;
};

const Container = styled.div`
  padding: 32px 15px;
  height: 100vh;
  background-color: #fafafa;

  ::-webkit-scrollbar {
    display: none;
    width: 0;
  }
  -ms-overflow-style: none;
`;

const InfoBox = styled.div`
  ${flex('center', 'center')}
  margin-top: 20px;
  padding: 24px;
  border-radius: 10px;
  background-color: #ffffff;
`;

const InfoItem = styled.div`
  ${flex('flex-start', 'center')}
  ${font(14, 400, 21)}
  width: 100%;
  color: #999999;
  white-space: nowrap;

  span {
    color: black;
    margin-left: 18px;
  }
`;

const SafetyRuleWrap = styled.div`
  margin-top: 10px;
  padding: 24px 30px;
  background-color: white;

  border-radius: 10px;
`;

const Date = styled.div`
  ${font(14, 700, 21)}
  margin-bottom: 18px;
  text-align: center;
  color: #15aedb;
`;

const SafetyRuleListWrap = styled.div`
  height: 100%;
  max-height: 330px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
    width: 0;
  }
  -ms-overflow-style: none;
`;

const SafetyRule = styled.div<IActive>`
  ${flex('flex-start', 'flex-start')}
  ${font(14, 400, 21)}
  margin-bottom: 14px;
  color: ${({ active }) => (active ? '#15aedb' : '#000000')};

  div {
    :first-child {
      padding-right: 7px;
    }
  }
`;

const StartRecordingBtn = styled.button`
  ${font(18, 700, 27)}
  width: 100%;
  margin-top: 65px;
  padding: 12px 0;
  text-align: center;
  border-radius: 5px;
  color: white;
  background-color: #15aedb;
`;
