import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

const fetchResetPassword = async ({ newPassword, token }: { newPassword: string; token: string }) => {
  await axios.patch(`${process.env.REACT_APP_API_ADDRESS}/user/password/reset?token=${token}`, {
    password: newPassword,
  });
};

export const useResetPasswordQuery = () =>
  useMutation(fetchResetPassword, {
    onError: (error) => {
      console.error(error);
    },
  });
