import { useMutation, useQueryClient } from '@tanstack/react-query';
import axiosClient from 'libs/axios/axios';

const editEmergencyProcedure = async (values: { targetId: number; inputValue: string }) => {
  const { targetId, inputValue } = values;

  const { data } = await axiosClient.patch(`/emergency-procedure/${targetId}`, {
    value: inputValue,
  });

  return data;
};

const useEditEmergencyProcedureQuery = () => {
  const queryClient = useQueryClient();

  return useMutation(editEmergencyProcedure, {
    onSuccess: () => {
      queryClient.invalidateQueries(['emergencyProcedure', 'list']);
    },
    onError: (error) => {
      alert('수정 실패. 다시 한 번 시도해주세요');
      console.error('edit emergencyProcedure error', error);
    },
  });
};

export default useEditEmergencyProcedureQuery;
