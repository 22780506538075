import { useState, ChangeEvent, useEffect, useMemo, useRef } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { useLocation, useParams } from 'react-router-dom';

import { TextInput } from './components';
import {
  ButtonModal,
  OperationTypeSelector,
  SearchList,
  ParticipantSelector,
  TemplateTitleInput,
  ResultMessage,
  ManagerSelector,
} from 'pages/components';
import {
  useGetOperationDetailListQuery,
  useAddOperationDetailQuery,
  useEditOperationDetailQuery,
  useDeleteOperationDetailQuery,
  useGetSafetyMeasureListQuery,
  useAddSafetyMeasureQuery,
  useEditSafetyMeasureQuery,
  useDeleteSafetyMeasureQuery,
  useGetSafetyRuleListQuery,
  useAddSafetyRuleQuery,
  useEditSafetyRuleQuery,
  useDeleteSafetyRuleQuery,
  useGetParticipantListQuery,
  useGetRecentParticipantListQuery,
  useGetTBMDetailQuery,
  useGetTemplateDetailQuery,
  useSaveTbmQuery,
  useEditTbmQuery,
  useAddEmergencyProcedureQuery,
  useGetEmergencyProcedureListQuery,
  useEditEmergencyProcedureQuery,
  useDeleteEmergencyProcedureQuery,
  useGetAttendeeListQuery,
  useAddAttendeeQuery,
  useDeleteAttendeeQuery,
} from './queries';
import { tbmInfoState, tbmSearchKeywordState } from 'pages/recoil/TbmManagement';
import { initialError } from './initialValue';
import { flex } from 'styles/flex';
import { font } from 'styles/fonts';

export default function TbmManagement() {
  const { id: templateId, date } = useParams();

  const { search } = useLocation();
  const isEditRef = useRef(search.includes('true'));
  const isSaveRequested = useRef(false);

  const [tbmInfo, setTbmInfo] = useRecoilState(tbmInfoState);
  const [tbmSearchKeyword, setTbmSearchKeyword] = useRecoilState(tbmSearchKeywordState);
  const [inputError, setInputError] = useState(initialError);
  const [activeModal, setActiveModal] = useState('');

  const {
    author,
    authorId,
    time,
    location,
    notice,
    operationType,
    operationDetailList,
    operationName,
    safetyMeasureList,
    safetyRuleList,
    participantList,
    emergencyProcedureList,
    attendeeList,
  } = tbmInfo;

  const {
    author: authorError,
    time: timeError,
    location: locationError,
    operationType: operationTypeError,
    operationDetail: operationDetailError,
    safetyMeasure: safetyMeasureError,
    safetyRule: safetyRuleError,
    emergencyProcedure: emergencyProcedureError,
    totalParticipant: totalParticipantError,
  } = inputError;

  const {
    operationDetailList: operationDetailKeyword,
    safetyMeasureList: safetyMeasureKeyword,
    safetyRuleList: safetyRuleKeyword,
    emergencyProcedureList: emergencyProcedureKeyword,
    participantList: participantListKeyword,
    recentList: recentListKeyword,
  } = tbmSearchKeyword;

  useGetTemplateDetailQuery(templateId, isEditRef.current, date);
  useGetTBMDetailQuery(+templateId, isEditRef.current);

  const operationDetailListQuery = useGetOperationDetailListQuery(operationDetailKeyword);
  const safetyMeasureListQuery = useGetSafetyMeasureListQuery(safetyMeasureKeyword);
  const safetyRuleListQuery = useGetSafetyRuleListQuery(safetyRuleKeyword);
  const participantListQuery = useGetParticipantListQuery(participantListKeyword);
  const recentListQuery = useGetRecentParticipantListQuery(recentListKeyword);
  const attendeeListQuery = useGetAttendeeListQuery();
  const emergencyProcedureListQuery = useGetEmergencyProcedureListQuery(emergencyProcedureKeyword);

  const { mutate: addOperationDetail } = useAddOperationDetailQuery();
  const { mutate: addSafetyMeasure } = useAddSafetyMeasureQuery();
  const { mutate: addSafetyRule } = useAddSafetyRuleQuery();
  const { mutate: addEmergencyProcedure } = useAddEmergencyProcedureQuery();
  const { mutate: addAttendee } = useAddAttendeeQuery();

  const { mutate: editOperationDetail } = useEditOperationDetailQuery();
  const { mutate: editSafetyMeasure } = useEditSafetyMeasureQuery();
  const { mutate: editSafetyRule } = useEditSafetyRuleQuery();
  const { mutate: editEmergencyProcedure } = useEditEmergencyProcedureQuery();

  const { mutate: deleteOperationDetail } = useDeleteOperationDetailQuery();
  const { mutate: deleteSafetyMeasure } = useDeleteSafetyMeasureQuery();
  const { mutate: deleteSafetyRule } = useDeleteSafetyRuleQuery();
  const { mutate: deleteEmergencyProcedure } = useDeleteEmergencyProcedureQuery();
  const { mutate: deleteAttendee } = useDeleteAttendeeQuery();

  const { mutate: saveTbm, isSuccess: saveSuccess, data: savedTbmId } =
    useSaveTbmQuery({ options: { setActiveModal } });
  const { mutate: editTbm, isSuccess: editSuccess, data: edittedTbmId } = useEditTbmQuery();

  const totalParticipantList = useMemo(() => [...participantList, ...attendeeList], [participantList, attendeeList]);

  const deleteTbmInput = (key: string, targetId: number) => {
    setTbmInfo((prev) => ({
      ...prev,
      [key]: prev[key].filter((el: any) => el.id !== targetId),
    }));
  };

  const handleTbmInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string) => {
    const { value } = e.target;

    setTbmInfo((prev) => ({ ...prev, [key]: value }));
    setInputError((prev) => ({ ...prev, [key]: Boolean(!value) }));
  };

  const handleInputError = (): boolean => {
    if (
      !author ||
      !time ||
      !location ||
      !operationDetailList.length ||
      !operationType ||
      !safetyMeasureList.length ||
      !safetyRuleList.length ||
      ![totalParticipantList].length ||
      !emergencyProcedureList.length
    ) {
      setInputError((prev) => ({
        ...prev,
        author: Boolean(!author),
        time: Boolean(!time),
        location: Boolean(!location),
        operationType: Boolean(!operationType),
        operationDetail: Boolean(!operationDetailList.length),
        safetyMeasure: Boolean(!safetyMeasureList.length),
        safetyRule: Boolean(!safetyRuleList.length),
        emergencyProcedure: Boolean(!emergencyProcedureList.length),
        totalParticipant: Boolean(!totalParticipantList.length),
      }));
      return false;
    }
    return true;
  };

  const toggleModal = (targetInput: string = '') => {
    setActiveModal(targetInput);
  };

  const handleEditTbm = () => {
    const requestBody = {
      author_id: authorId,
      datetime: `${date.split('(')[0]} ${time}`,
      notice,
      location,
      is_complete: true,
      is_template: false,
      operation_id: operationType || null,
      operation_detail_list: operationDetailList?.map((operation) => operation.id),
      safety_rule_list: safetyRuleList?.map((rule) => rule.id),
      safety_measure_list: safetyMeasureList?.map((measure) => measure.id),
      participant_list: participantList?.map((participant) => participant.id),
      emergency_procedure_list: emergencyProcedureList?.map((procedure) => procedure.id),
      attendee_list: attendeeList?.map((attendee) => attendee.id),
    };

    editTbm({ tbmId: templateId, requestBody });
  };

  const handleTbmSaveOptions = ({
    isCompleted,
    isTemplate = false,
    templateTitle,
  }: {
    isCompleted: boolean;
    isTemplate?: boolean;
    templateTitle?: string;
  }) => {
    // save as template or temporarily
    if (isTemplate || !isCompleted) {
      return saveTbm({ tbmInfo, date, isCompleted, isTemplate, templateTitle });
    }

    isSaveRequested.current = true;
    if (!handleInputError()) {
      return;
    }
    setActiveModal('알림');
  };

  const handleSaveTbm = () => {
    saveTbm({ tbmInfo, date, isCompleted: true, isTemplate: false });
  };

  const postMessageToRN = (result: string, tbmId: number) => {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        saveResult: result,
        tbmId,
      }),
    );
  };

  useEffect(() => {
    // seperate action by save type after get success message
    if (!(saveSuccess || editSuccess)) return;

    if (activeModal.includes('템플릿저장')) {
      return setActiveModal('');
    }

    const targetSuccess = saveSuccess ? 'saveSuccess' : 'editSuccess';
    const targetTbmId = savedTbmId || edittedTbmId;

    postMessageToRN(targetSuccess, targetTbmId);
    setActiveModal('');
  }, [saveSuccess, editSuccess]);

  useEffect(() => {
    // check input error only after save request
    if (isSaveRequested.current) {
      handleInputError();
    }
  }, [operationDetailList, operationType, safetyMeasureList, safetyRuleList, emergencyProcedureList, participantList]);

  useEffect(() => {
    setTbmInfo((prev) => ({ ...prev, date }));
  }, [date]);

  return (
    <Container>
      <ButtonModal isOpen={activeModal === '작업유형'} title="작업유형" onClose={() => toggleModal('')}>
        <OperationTypeSelector onClose={() => toggleModal('')} />
      </ButtonModal>
      <ButtonModal isOpen={activeModal.includes('작업내용')} title="작업내용" onClose={() => toggleModal('')}>
        <SearchList
          dataKey="operationDetailList"
          query={operationDetailListQuery}
          request={{
            addRequest: addOperationDetail,
            editRequest: editOperationDetail,
            deleteRequest: deleteOperationDetail,
          }}
          onChangeKeyword={setTbmSearchKeyword}
          onClose={() => toggleModal('')}
        />
      </ButtonModal>
      <ButtonModal isOpen={activeModal.includes('위험요소')} title="위험요소" onClose={() => toggleModal('')}>
        <SearchList
          dataKey="safetyMeasureList"
          query={safetyMeasureListQuery}
          request={{
            addRequest: addSafetyMeasure,
            editRequest: editSafetyMeasure,
            deleteRequest: deleteSafetyMeasure,
          }}
          onChangeKeyword={setTbmSearchKeyword}
          onClose={() => toggleModal('')}
        />
      </ButtonModal>
      <ButtonModal isOpen={activeModal.includes('안전수칙')} title="안전수칙" onClose={() => toggleModal('')}>
        <SearchList
          dataKey="safetyRuleList"
          query={safetyRuleListQuery}
          request={{
            addRequest: addSafetyRule,
            editRequest: editSafetyRule,
            deleteRequest: deleteSafetyRule,
          }}
          onChangeKeyword={setTbmSearchKeyword}
          onClose={() => toggleModal('')}
        />
      </ButtonModal>
      <ButtonModal
        isOpen={activeModal.includes('비상 시 대처요령')}
        title="비상 시 대처요령"
        onClose={() => toggleModal('')}
      >
        <SearchList
          dataKey="emergencyProcedureList"
          query={emergencyProcedureListQuery}
          request={{
            addRequest: addEmergencyProcedure,
            editRequest: editEmergencyProcedure,
            deleteRequest: deleteEmergencyProcedure,
          }}
          onChangeKeyword={setTbmSearchKeyword}
          onClose={() => toggleModal('')}
        />
      </ButtonModal>
      <ButtonModal isOpen={activeModal.includes('참석자')} title="참석자" onClose={() => toggleModal('')}>
        <ParticipantSelector
          data={{
            participantList,
            attendeeList,
          }}
          query={{ participantListQuery, recentListQuery, attendeeListQuery }}
          request={{ addAttendee, deleteAttendee }}
          onClose={() => toggleModal('')}
          onConfirm={setTbmInfo}
        />
      </ButtonModal>
      <ButtonModal isOpen={activeModal.includes('템플릿저장')} title="템플릿으로 저장" onClose={() => toggleModal('')}>
        <TemplateTitleInput saveRequest={handleTbmSaveOptions} />
      </ButtonModal>
      <ButtonModal isOpen={activeModal.includes('알림')} title="알림" onClose={() => toggleModal('')}>
        <ResultMessage
          data={{ date, time, totalParticipantCount: totalParticipantList?.length || 0 }}
          handleSave={handleSaveTbm}
          onClose={() => toggleModal('')}
        />
      </ButtonModal>
      <ButtonModal
        isOpen={activeModal.includes('안전 미팅 담당자')}
        title="안전 미팅 담당자"
        onClose={() => toggleModal('')}
      >
        <ManagerSelector onClose={() => toggleModal('')} />
      </ButtonModal>
      <Contents>
        <TextInputWrap>
          <TextInput
            isError={timeError}
            label="예정시간"
            type="input"
            value={time}
            onChangeInput={(e: ChangeEvent<HTMLInputElement>) => handleTbmInput(e, 'time')}
          />
        </TextInputWrap>
        <TextInputWrap>
          <TextInput
            btnText="선택"
            isError={authorError}
            label="안전미팅 담당자"
            type="buttonInput"
            value={author}
            onBtnClick={() => toggleModal('안전 미팅 담당자')}
          />
        </TextInputWrap>
        <TextInputWrap>
          <TextInput
            isError={locationError}
            label="작업위치"
            type="input"
            value={location}
            onChangeInput={(e: ChangeEvent<HTMLInputElement>) => handleTbmInput(e, 'location')}
          />
        </TextInputWrap>
        <TextInputWrap>
          <TextInput
            label="작업 전달사항"
            type="textArea"
            value={notice}
            onChangeInput={(e: ChangeEvent<HTMLTextAreaElement>) => handleTbmInput(e, 'notice')}
          />
        </TextInputWrap>
        <TextInputWrap>
          <TextInput
            btnText="선택"
            isError={operationTypeError}
            label="작업유형"
            type="buttonInput"
            value={operationName}
            onBtnClick={() => toggleModal('작업유형')}
          />
        </TextInputWrap>
        <TextInputWrap>
          {!operationDetailList.length ? (
            <TextInput
              btnText="선택"
              isError={operationDetailError}
              label="작업내용"
              type="buttonInput"
              onBtnClick={() => toggleModal('작업내용')}
            />
          ) : (
            <TextInputList>
              {operationDetailList.map((operationDetail, idx) => (
                <TextInput
                  key={`input-${operationDetail?.id}`}
                  btnText="삭제"
                  label={`작업내용 ${idx + 1}`}
                  type="buttonInput"
                  value={operationDetail?.explanation}
                  onBtnClick={() => deleteTbmInput('operationDetailList', operationDetail?.id)}
                />
              ))}
              <AddBtn onClick={() => toggleModal('작업내용')}>작업내용 추가</AddBtn>
            </TextInputList>
          )}
        </TextInputWrap>
        <TextInputWrap>
          {!safetyMeasureList.length ? (
            <TextInput
              btnText="선택"
              isError={safetyMeasureError}
              label="위험요소"
              type="buttonInput"
              onBtnClick={() => toggleModal('위험요소')}
            />
          ) : (
            <TextInputList>
              {safetyMeasureList.map((safetyMeasure, idx) => {
                const { id, safetyMeasureName } = safetyMeasure;
                return (
                  <TextInput
                    key={`input-safetyMeasure${id}`}
                    disabled
                    btnText="삭제"
                    label={`위험요소 ${idx + 1}`}
                    type="buttonInput"
                    value={safetyMeasureName}
                    onBtnClick={() => deleteTbmInput('safetyMeasureList', safetyMeasure?.id)}
                  />
                );
              })}
              <AddBtn onClick={() => toggleModal('위험요소')}>위험 요소 추가</AddBtn>
            </TextInputList>
          )}
        </TextInputWrap>
        <TextInputWrap>
          {!safetyRuleList.length ? (
            <TextInput
              btnText="선택"
              isError={safetyRuleError}
              label="안전수칙"
              type="buttonInput"
              onBtnClick={() => toggleModal('안전수칙')}
            />
          ) : (
            <TextInputList>
              {safetyRuleList.map((safetyRule, idx) => {
                const { id: ruleId, script } = safetyRule;
                return (
                  <TextInput
                    key={`input-safetyRule${ruleId}`}
                    btnText="삭제"
                    label={`안전수칙 ${idx + 1}`}
                    type="buttonInput"
                    value={script}
                    onBtnClick={() => deleteTbmInput('safetyRuleList', ruleId)}
                  />
                );
              })}
              <AddBtn onClick={() => toggleModal('안전수칙')}>안전 수칙 추가</AddBtn>
            </TextInputList>
          )}
        </TextInputWrap>
        <TextInputWrap>
          {!emergencyProcedureList.length ? (
            <TextInput
              btnText="선택"
              isError={emergencyProcedureError}
              label="비상 시 대처요령"
              type="buttonInput"
              onBtnClick={() => toggleModal('비상 시 대처요령')}
            />
          ) : (
            <TextInputList>
              {emergencyProcedureList.map((item) => {
                const { id, value } = item;
                return (
                  <TextInput
                    key={`input-emergencyProcedure${id}`}
                    btnText="삭제"
                    label="비상 시 대처요령"
                    type="buttonInput"
                    value={value}
                    onBtnClick={() => deleteTbmInput('emergencyProcedureList', id)}
                  />
                );
              })}
              <AddBtn onClick={() => toggleModal('비상 시 대처요령')}>비상 시 대처요령 추가</AddBtn>
            </TextInputList>
          )}
        </TextInputWrap>
        <TextInputWrap>
          {!totalParticipantList.length ? (
            <TextInput
              btnText="선택"
              isError={totalParticipantError}
              label="참석자"
              type="buttonInput"
              onBtnClick={() => toggleModal('참석자')}
            />
          ) : (
            <TextInputList>
              {totalParticipantList.map((participant) => {
                const { id: userId, name, teamName = '비회원' } = participant;
                return (
                  <TextInput
                    key={`input-participant${userId}`}
                    btnText="삭제"
                    label="참석자"
                    type="buttonInput"
                    value={`${name} / ${teamName}`}
                    onBtnClick={
                      () => deleteTbmInput(teamName === '비회원' ? 'attendeeList' : 'participantList', userId)
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                  />
                );
              })}
              <AddBtn onClick={() => toggleModal('참석자')}>참석자 추가</AddBtn>
            </TextInputList>
          )}
        </TextInputWrap>
      </Contents>
      <SaveBtn onClick={() => (isEditRef.current ? handleEditTbm() : handleTbmSaveOptions({ isCompleted: true }))}>
        저장
      </SaveBtn>
      <BtnWrap>
        <TempBtn
          onClick={() => {
            handleTbmSaveOptions({ isCompleted: false });
          }}
        >
          임시저장
        </TempBtn>
        <TempBtn onClick={() => toggleModal('템플릿저장')}>템플릿으로 저장</TempBtn>
      </BtnWrap>
    </Container>
  );
}

const Container = styled.div`
  background-color: #f5f5f5;
  height: 100vh;
  width: 100%;
  padding: 20px 15px;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
    width: 0;
  }

  -ms-overflow-style: none;
`;

const Contents = styled.div`
  section:last-child {
    margin-bottom: 60px;
  }
`;

const TextInputWrap = styled.section`
  margin-bottom: 45px;
`;

const TextInputList = styled.div`
  ${flex('', '', 'column')}
  gap: 25px;
`;

const SaveBtn = styled.button`
  ${font(18, 700, 27)}
  ${flex('center', 'center')}
  width: 100%;
  background-color: #15aedb;
  padding: 12px 0;
  color: #ffffff;
  border-radius: 5px;
`;

const BtnWrap = styled.div`
  ${flex('space-between', '')};
  width: 100%;
  margin: 12px 0 20px;
  button:nth-child(2) {
    margin-left: 10px;
  }
`;

const TempBtn = styled.button`
  flex: 1;
  ${font(14, 700, 27)}
  ${flex('center', 'center')}
  color: #15AEDB;
  border: 1px solid #15aedb;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 8px 0;
`;

const AddBtn = styled(TempBtn)`
  width: 100%;
`;
