import { useMutation, useQueryClient } from '@tanstack/react-query';
import axiosClient from 'libs/axios/axios';
import { IRecordingInfo } from 'types/recording';

const fetchRecording = async (recordingInfo: IRecordingInfo) => {
  const { ruleId, transcribed, audioUrl, attendeeId, tbmId, date } = recordingInfo;

  const { data } = await axiosClient.patch(`/attendee/safety-rule-log/${ruleId}`, {
    completed: true,
    final_sil_time_limit: 5,
    initial_sil_time_limit: 5,
    recording_path: audioUrl,
    transcribed,
    word_err_limit: 5,
    attendee_id: attendeeId,
    tbm_id: tbmId,
    date,
  });

  return data;
};

export const usePatchRecordingQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(fetchRecording, {
    onSuccess: () => {
      queryClient.invalidateQueries(['attendee', 'safetyRuleList']);
    },
    onError: () => {
      alert('안전수칙 전송에 실패하였습니다. ');
    },
  });
};
