import { useMutation, useQueryClient } from '@tanstack/react-query';
import axiosClient from 'libs/axios/axios';

const addEmergencyProcedure = async (emergencyProcedure: string) => {
  const { data } = await axiosClient.post('/emergency-procedure', {
    value: emergencyProcedure,
  });

  return data;
};

const useAddEmergencyProcedureQuery = () => {
  const queryClient = useQueryClient();

  return useMutation(addEmergencyProcedure, {
    onSuccess: () => {
      queryClient.invalidateQueries(['emergencyProcedure', 'list']);
    },
    onError: (error) => {
      alert('추가 실패. 다시 한 번 시도해주세요');
      console.error('add emergencyProcedure error', error);
    },
  });
};

export default useAddEmergencyProcedureQuery;
