import { useMemo } from 'react';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import axiosClient from 'libs/axios/axios';

import { useRecoilValue } from 'recoil';
import { dateRangeState } from 'pages/recoil/createTBM';

import { snakeToCamel } from 'util/snakeToCamel';
import { ITBMList } from 'types/tbm';

const fetchTBMByDateQuery = async (startDate: string, endDate: string) => {
  const res = await axiosClient.get(`/tbm?start_date=${startDate}&end_date=${endDate}`);
  const cameled = snakeToCamel(res.data);

  return cameled;
};

export const useTBMByDateQuery = () => {
  const dateRange = useRecoilValue(dateRangeState);
  const { startDate, endDate } = dateRange;
  const formattedStartDate = useMemo(() => startDate.format('YYYY-MM-DD'), [startDate]);
  const formattedEndDate = useMemo(() => endDate.format('YYYY-MM-DD'), [endDate]);

  return useQuery<ITBMList[], AxiosError>(
    ['CreateTBM', 'tbmListByDate', formattedStartDate, formattedEndDate],
    () => fetchTBMByDateQuery(formattedStartDate, formattedEndDate),
    {
      onError: (error) => {
        console.error('fetching tbm List by date error', error);
      },
    },
  );
};
