import { useMutation } from '@tanstack/react-query';
import axiosClient from 'libs/axios/axios';

interface IEditTbmType {
  tbmId: string;
  requestBody: any;
}

const fetchEditTbm = async ({ tbmId, requestBody }: IEditTbmType) => {
  const { data } = await axiosClient.patch(`/tbm/${tbmId}`, requestBody);

  return data;
};

const useEditTbmQuery = () =>
  useMutation(['tbm', 'edit'], fetchEditTbm, {
    onSuccess: () => {
      alert('안전미팅 수정이 완료되었습니다.');
    },
    onError: (error) => {
      console.error(JSON.stringify(error));
    },
  });

export default useEditTbmQuery;
