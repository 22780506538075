import styled, { css } from 'styled-components';

import AnnounceList from './components/AnnounceList';

import { flex } from 'styles/flex';
import { font } from 'styles/fonts';
import { useRecoilState } from 'recoil';
import { announcementState } from 'pages/recoil/announcement';

export default function Announcement() {
  const [announcement, setAnnouncement] = useRecoilState(announcementState);
  const { category } = announcement;

  return (
    <Container>
      <Nav>
        <NavText isSelected={category === ''} onClick={() => setAnnouncement({ category: '' })}>
          전체
        </NavText>
        <Line />
        <NavText isSelected={category === 'emergency'} onClick={() => setAnnouncement({ category: 'emergency' })}>
          긴급
        </NavText>
        <Line />
        <NavText isSelected={category === 'notice'} onClick={() => setAnnouncement({ category: 'notice' })}>
          알림
        </NavText>
        <Line />
        <NavText isSelected={category === 'information'} onClick={() => setAnnouncement({ category: 'information' })}>
          정보 공유
        </NavText>
        <Line />
        <NavText isSelected={category === 'others'} onClick={() => setAnnouncement({ category: 'others' })}>
          기타
        </NavText>
      </Nav>
      <AnnounceList />
    </Container>
  );
}

const Container = styled.div`
  ${flex('center', 'center', 'column')}
  background-color: #FAFAFA;
`;

const Nav = styled.div`
  ${flex('space-between', 'center')}
  height: 28px;
  width: 100%;
  margin: 20px 0 18px;
  padding: 0 30px;
`;

const NavText = styled.div<{ isSelected?: boolean }>`
  ${font(16, 400, 22)}

  color: #000000de;
  ${({ isSelected }) =>
    isSelected &&
    css`
      color: #15aedb;
    `}
`;

const Line = styled.div`
  width: 1px;
  height: 70%;
  background-color: #d9d9d9;
`;
