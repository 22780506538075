import { useState, useEffect } from 'react';

import dayjs from 'dayjs';
import { useRecoilValue } from 'recoil';
import styled, { css } from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';

import OrganizationChart from '../OrganizationChart';

import { announcementState, organizationChartState } from 'pages/recoil/announcement';
import { fontScaleState } from 'pages/recoil/fonts';
import { useAnnouncementDetailQuery } from 'pages/Announcement/queries/useAnnouncementDetailQuery';

import { flex } from 'styles/flex';
import { font } from 'styles/fonts';

import { IAnnouncementDetail } from 'types/announcement';
import { IScaledText } from 'types/fonts';

export default function AnnounceDetail() {
  const params = useParams();
  const navigate = useNavigate();
  const { category } = useRecoilValue(announcementState);
  const chartData = useRecoilValue(organizationChartState);
  const fontScale = useRecoilValue(fontScaleState);
  const [detailData, setDetailData] = useState<IAnnouncementDetail>(chartData);

  const { data, isSuccess } = useAnnouncementDetailQuery(+params.id, category);

  const handleBack = () => {
    navigate('/announcement');
  };

  const handleNavigateBtnClick = (id: number) => {
    if (!id) return;
    navigate(`/announcement/detail/${id}`);
  };

  const handleFileDownLoad = (attachmentName: string, attachmentPath: string) => {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: 'file',
        file: { attachmentPath, attachmentName },
      }),
    );
  };

  useEffect(() => {
    if (!isSuccess) return;

    setDetailData(data);
  }, [isSuccess, data]);

  return (
    <Container fontScale={fontScale}>
      <Content>
        <ContentWrap>
          <ContentHeader>
            <TitleHeader>
              <ContentTitle emergency={detailData?.category === '긴급'}>{detailData?.category}</ContentTitle>
              <ContentDate>{dayjs(detailData?.createdAt).format('YYYY-MM-DD')}</ContentDate>
            </TitleHeader>
            <ContentText>{detailData?.title}</ContentText>
          </ContentHeader>
          {+params.id === chartData.id ? (
            <ChartBox>
              <OrganizationChart />
            </ChartBox>
          ) : (
            <>
              <Box>{detailData?.content}</Box>
              <ContentFooter>
                <FooterText>첨부파일</FooterText>
                <FileNameList>
                  {detailData?.attachment.map((attachment, index) => (
                    <FileName
                      key={`announcement-attachment-${index}`}
                      onClick={() => handleFileDownLoad(attachment.name, attachment.path)}
                    >
                      {attachment.name}
                    </FileName>
                  ))}
                </FileNameList>
              </ContentFooter>
            </>
          )}
        </ContentWrap>
        <PrevBtn onClick={() => handleNavigateBtnClick(detailData?.previous.id)}>
          <FooterText>이전글</FooterText>
          <BtnText isDisabled={!detailData?.previous.title}>
            {detailData?.previous.title ? detailData?.previous.title : '이전 글이 없습니다.'}
          </BtnText>
        </PrevBtn>
        <NextBtn onClick={() => handleNavigateBtnClick(detailData?.next.id)}>
          <FooterText>다음글</FooterText>
          <BtnText isDisabled={!detailData?.next.title}>
            {detailData?.next.title ? detailData?.next.title : '다음 글이 없습니다.'}
          </BtnText>
        </NextBtn>
        <BackBtn onClick={() => handleBack()}>목록</BackBtn>
      </Content>
    </Container>
  );
}

const NormalTextBox = styled.div``;
const NormalText = styled.p``;
const BoldText = styled.p``;

const Container = styled.div<IScaledText>`
  padding: 0 15px;
  background: #fafafa;
  height: 100vh;

  ${NormalTextBox} {
    ${({ fontScale }) => font(14 + fontScale, 400, (14 + fontScale) * 1.5)}
  }

  ${NormalText} {
    ${({ fontScale }) => font(14 + fontScale, 400, (14 + fontScale) * 1.5)}
  }

  ${BoldText} {
    ${({ fontScale }) => font(14 + fontScale, 700, (14 + fontScale) * 1.5)}
  }
`;

const Content = styled.div`
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

const ContentWrap = styled.div`
  width: 100%;
  min-height: 450px;
  background: #ffffff;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 19px;
  padding: 21px;
`;

const ContentHeader = styled.div`
  border-bottom: 1px solid #d9d9d9;
  min-height: 73px;
  padding-bottom: 16px;
`;

const TitleHeader = styled.div`
  display: flex;
  gap: 18px;
  margin-bottom: 18px;
`;

const ContentTitle = styled(NormalText)<{ emergency: boolean }>`
  color: #999999;

  ${({ emergency }) =>
    emergency &&
    css`
      color: red;
    `}
`;

const ContentDate = styled(NormalText)`
  color: #999999;
`;

const ContentText = styled(BoldText)``;

const Box = styled(NormalTextBox)`
  width: 100%;
  height: 300px;
  padding: 18px 0;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-y: scroll;
`;

const ChartBox = styled(Box)`
  height: 354px;
`;

const ContentFooter = styled.div`
  ${flex('', 'center')}
  border-top: 1px solid #d9d9d9;
  padding-top: 18px;
`;

const FooterText = styled(NormalText)`
  width: max-content;
  text-align: left;
  margin-right: 18px;
  color: #999999;
`;

const FileNameList = styled.div`
  ${flex('', '', 'column')}
  width: calc(100% - 7em);
`;

const FileName = styled(NormalText)`
  text-decoration-line: underline;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  cursor: pointer;
`;

const PrevBtn = styled.button`
  ${flex('flex-start', 'center')}
  width: 100%;
  min-height: 63px;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 19px;
  padding: 21px;
`;

const NextBtn = styled(PrevBtn)``;

const BtnText = styled(BoldText)<{ isDisabled: boolean }>`
  width: calc(100% - 4em);
  flex-shrink: 100;
  text-align: left;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: #999999;
    `}
`;

const BackBtn = styled.button`
  background: #15aedb;
  border-radius: 4px;
  height: 58px;
  ${flex('center', 'center')};
  color: white;
  ${font(18, 600, 22)};
  width: 100%;
  margin-bottom: 10px;
`;
