import styled from 'styled-components';

import { flex } from 'styles/flex';
import { font } from 'styles/fonts';

import { IOrganizationChart } from 'types/announcement';

interface ITablePropTypes {
  title: string;
  itemList: IOrganizationChart[] | undefined;
}

export default function Table({ title, itemList }: ITablePropTypes) {
  return (
    <Container>
      <Header>{title}</Header>
      {itemList?.map((el, idx) => (
        <Row key={`Row-${idx}`}>
          <Position>{el.position}</Position>
          <Name>{el.name}</Name>
        </Row>
      ))}
    </Container>
  );
}

const Container = styled.div`
  width: 80%;
  div:last-child {
    border-radius: 0px 0px 4px 4px;
  }
`;

const Header = styled.div`
  ${flex('center', 'center')}
  ${font(14, 400, 16)}
  background: #15AEDB;
  height: 40px;
  border-radius: 4px 4px 0px 0px;
  color: #ffffff;
`;

const Row = styled.div`
  ${flex('center', 'center')}
  ${font(14, 400, 16)}
  height: 40px;
  border: 1px solid #d9d9d9;
  border-top: none;
`;

const Position = styled.p`
  ${font(14, 400, 16)}
  ${flex('flex-end', '')}
  margin-right: 12px;
  color: #999999;
  width: 100%;
`;

const Name = styled(Position)`
  ${flex('flex-start', '')}
  color: #000000;
  margin-left: 15px;
`;
