import { useInfiniteQuery } from '@tanstack/react-query';

import axiosClient from 'libs/axios/axios';
import { snakeToCamel } from 'util/snakeToCamel';

interface IGetSafetyRuleParams {
  pageParam: number;
  pageSize?: number;
  keyword?: string;
}

interface IGetSafetyRuleResponse {
  safetyRuleId: number;
  script: string;
  operationId: number;
  operation: string;
}

const getSafetyRuleList = async (values: IGetSafetyRuleParams) => {
  const { pageParam, pageSize = 10, keyword = '' } = values;

  const { data } = await axiosClient.get(`/safety-rule?page_num=${pageParam}&page_size=${pageSize}&keyword=${keyword}`);
  const cameled = snakeToCamel(data.data);

  return {
    result: cameled.map((item: IGetSafetyRuleResponse) => ({
      id: item.safetyRuleId,
      script: item.script,
    })),
    nextPage: pageParam + 1,
    isLast: !!data.next,
  };
};

const useGetSafetyRuleListQuery = (keyword?: string) =>
  useInfiniteQuery(['safetyRule', 'list', keyword], ({ pageParam = 1 }) => getSafetyRuleList({ pageParam, keyword }), {
    getNextPageParam: (lastPage) => {
      if (lastPage.isLast) return lastPage.nextPage;
      return undefined;
    },
    onError: (error) => {
      console.error('get safetyRule list error', error);
    },
  });

export default useGetSafetyRuleListQuery;
