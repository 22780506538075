/* eslint-disable @typescript-eslint/no-shadow */
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import axiosClient from 'libs/axios/axios';
import { tbmInfoState } from 'pages/recoil/TbmManagement';
import { useSetRecoilState } from 'recoil';
import { ITBMDetailType } from 'types/tbmManagement';

import { snakeToCamel } from 'util/snakeToCamel';

const fetchTBMDetail = async (tbmId: number) => {
  const { data } = await axiosClient.get(`/tbm/detail/${tbmId}`);

  const camelData = snakeToCamel(data);

  return camelData;
};

const useGetTBMDetailQuery = (id: number, isEdit: boolean) => {
  const setTbmInfo = useSetRecoilState(tbmInfoState);

  return useQuery<ITBMDetailType, AxiosError>(['TBM', 'detail'], () => fetchTBMDetail(id), {
    enabled: isEdit,
    onSuccess: (data) => {
      const {
        author,
        authorId,
        date,
        id,
        location,
        notice,
        operationId,
        operationDetails,
        operationName,
        participants,
        safetyMeasures,
        safetyRules,
        time,
        emergencyProcedures,
        attendeeList,
      } = data;

      const safetyMeasureList = safetyMeasures.map(({ id, name }) => ({
        id,
        safetyMeasureName: name,
        safetyMeasureType: '',
      }));

      const participantList = participants.map(({ id, name, teamName }) => ({
        id,
        name,
        teamName,
      }));

      setTbmInfo({
        id,
        time,
        date,
        author,
        authorId,
        location,
        notice,
        operationType: operationId,
        operationDetailList: operationDetails,
        operationName,
        safetyMeasureList,
        safetyRuleList: safetyRules,
        participantList,
        emergencyProcedureList: emergencyProcedures,
        attendeeList,
      });
    },
    onError: (error) => {
      console.error('TBM Detail error:', error);
    },
  });
};

export default useGetTBMDetailQuery;
