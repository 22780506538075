const getScreenHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
      const resizeVh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${resizeVh}px`);
    });
  };

  export default getScreenHeight;
