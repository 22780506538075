import { ChangeEvent } from 'react';
import styled from 'styled-components';

import { flex } from 'styles/flex';
import { font } from 'styles/fonts';

interface IAddContentsProps {
  inputValue: string;
  handleAddBtnClick: () => void;
  handleTextInput: (e: ChangeEvent) => void;
}

function AddContents({ inputValue, handleAddBtnClick, handleTextInput }: IAddContentsProps) {
  return (
    <>
      <TextArea placeholder="글을 작성해주세요." value={inputValue} onChange={(e) => handleTextInput(e)} />
      <BtnList>
        <BtnWrap>
          <AddBtn disabled={!inputValue} onClick={() => handleAddBtnClick()}>
            저장
          </AddBtn>
        </BtnWrap>
      </BtnList>
    </>
  );
}

export default AddContents;

const TextArea = styled.textarea`
  ${font(12, 400, 18)}
  width: 100%;
  height: calc(202px + 66px);
  padding: 12px;
  margin-bottom: 30px;
  background: #ffffff;
  border: 1px solid #c0c0c0;
  border-radius: 5px;

  ::placeholder {
    color: #999999;
  }
`;

const BtnList = styled.div`
  ${flex('', 'center')}
  flex: 1;
  gap: 10px;
`;

const BtnWrap = styled.div`
  display: flex;
  flex: 1;
`;

const AddBtn = styled.button`
  ${font(18, 500, 17)}
  ${flex('center', 'center')}
  height: 52px;
  flex: 1;
  border-radius: 5px;
  text-align: center;
  background: #15aedb;
  color: #ffffff;
`;
