import { atom } from 'recoil';
import { IAnnouncementCategory, IAnnouncementDetail } from 'types/announcement';

export const announcementState = atom<IAnnouncementCategory>({
  key: 'announcementState',
  default: { category: '' },
});

export const organizationChartState = atom<IAnnouncementDetail>({
  key: 'organizationChartState',
  default: {
    id: 0,
    createdAt: '',
    category: '',
    content: '',
    title: '',
    attachment: [
      {
        id: 0,
        path: '',
        name: '',
        type: '',
      },
    ],
    next: {
      id: 0,
      createdAt: '',
      category: '',
      title: '',
    },
    previous: {
      id: 0,
      createdAt: '',
      category: '',
      title: '',
    },
  },
});
