import { HTMLAttributes } from 'react';

import styled, { css } from 'styled-components';

import Modal from '../Modal';

import { flex } from 'styles/flex';
import { font } from 'styles/fonts';
import { fitImg } from 'styles/mixins';

interface IButtonModalProps extends HTMLAttributes<HTMLDivElement> {
  buttons?: { type: string; name: string; action: () => void }[];
  isOpen: boolean;
  isShowCloseBtn?: boolean;
  title?: string;
  onClose: () => void;
}

export default function ButtonModal({
  children,
  buttons,
  isOpen,
  isShowCloseBtn = true,
  title = '',
  onClose,
}: IButtonModalProps) {
  return (
    <Modal bgColor="rgba(0, 0, 0, 0.5)" closeModal={onClose} isOpen={isOpen}>
      <Container>
        <Header>
          <TitleWrap>{title && <Title>{title}</Title>}</TitleWrap>
          {isShowCloseBtn && (
            <CloseBtn onClick={onClose}>
              <CloseIcon>
                <img alt="close" src="/icons/ic-close.svg" />
              </CloseIcon>
            </CloseBtn>
          )}
        </Header>
        <Contents>{children}</Contents>
        <BtnList>
          {buttons?.map((button, idx) => {
            const { type, name, action } = button;
            return (
              <BtnWrap key={`modal-btn-${idx}`} type={type} onClick={action}>
                <Button>{name}</Button>
              </BtnWrap>
            );
          })}
        </BtnList>
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  ${flex('space-between', '', 'column')}
  width: 330px;
  padding: 30px;
  border-radius: 10px;
`;

const Header = styled.div`
  ${flex('center', 'flex-start')}
`;

const TitleWrap = styled.div`
  ${flex('', '', 'column')}
  gap: 5px;
`;

const Title = styled.div`
  ${font(20, 700, 18)}
  color: #000000;
  position: relative;
`;

const CloseBtn = styled.button`
  ${flex('center', 'center')}
  width: 31px;
  height: 31px;
  position: absolute;
  right: 20px;
  top: 23px;
`;

const CloseIcon = styled.div`
  ${flex('', 'center')}
  ${fitImg};
`;

const Contents = styled.div``;

const BtnList = styled.div`
  ${flex('', 'center')}
  flex: 1;
  gap: 10px;
`;

const Confirm = css`
  background: #15aedb;
  color: #ffffff;
`;

const Close = css`
  background: #d9d9d9;
  color: #000000;
`;

const Deleted = css`
  background: #ea3313;
  color: #ffffff;
`;

const BTN_TYPES = {
  Confirm,
  Close,
  Deleted,
};

const BtnWrap = styled.div<{ type: string }>`
  display: flex;
  flex: 1;
  button {
    ${({ type }) => BTN_TYPES[type]}
  }
`;

const Button = styled.button`
  ${font(18, 500, 17)}
  ${flex('center', 'center')}
  height: 52px;
  flex: 1;
  border-radius: 5px;
  text-align: center;
`;
