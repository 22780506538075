import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';

import { tbmInfoState } from 'pages/recoil/TbmManagement';
import { useGetOperationTypeQuery } from './queries/useGetOperationTypeQuery';
import { useAddOperationTypeQuery } from './queries/useAddOperationTypeQuery';
import { useDeleteOperationTypeQuery } from './queries/useDeleteOperationTypeQuery';
import useIntersection from 'hooks/useInterSect';

import { flex } from 'styles/flex';
import { font } from 'styles/fonts';
import { fitImg } from 'styles/mixins';

import { IOperationType } from 'types/tbmManagement';

interface IOperationTypeSelectorProps {
  onClose: Function;
}

export default function OperationTypeSelector({ onClose }: IOperationTypeSelectorProps) {
  const [tbmInfo, setTbmInfo] = useRecoilState(tbmInfoState);
  const { operationType, operationName } = tbmInfo;

  const [targetItem, setTargetItem] = useState({
    targetOperationId: operationType,
    targetOperationName: operationName,
  });
  const [inputKeyword, setInputKeyword] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const { targetOperationId, targetOperationName } = targetItem;

  const { data: querydata, isLoading, hasNextPage, isFetching, fetchNextPage } = useGetOperationTypeQuery();

  const { mutate: addOperationType, isSuccess } = useAddOperationTypeQuery();
  const { mutate: deleteOperationType } = useDeleteOperationTypeQuery();

  const operationTypeList = useMemo(
    () => querydata?.pages.flatMap(({ result }: { result: IOperationType[] }) => result) || [],
    [querydata],
  );

  const handleItemClick = (id: number, value: string) => {
    setTargetItem((prev) => ({ ...prev, targetOperationId: id, targetOperationName: value }));
  };

  const handleConfirmBtnClick = () => {
    setTbmInfo((prev) => ({ ...prev, operationType: targetOperationId, operationName: targetOperationName }));
    onClose();
  };

  const handleDeleteBtnClick = () => {
    if (targetOperationId) {
      deleteOperationType(targetOperationId);
    }
  };

  const handleKeywordInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setInputKeyword(value);
  };

  const handleItemAdd = () => {
    if (inputKeyword) {
      addOperationType(inputKeyword);
    }
  };

  const ref = useIntersection(async (entry, observer) => {
    observer.unobserve(entry.target);
    if (hasNextPage && !isFetching) {
      fetchNextPage();
    }
  });

  useEffect(() => {
    const targetData = operationTypeList?.filter((data) => data.name.includes(inputKeyword));
    setFilteredData(targetData);
  }, [inputKeyword]);

  useEffect(() => {
    // reset search input when add operationType request success.
    if (isSuccess) {
      setInputKeyword('');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (operationTypeList) {
      setFilteredData(operationTypeList);
    }
  }, [operationTypeList]);

  if (isLoading) return <div>Loading...</div>;

  return (
    <Container>
      <Input>
        <InputField maxLength={20} placeholder="검색 및 입력해 주세요." onChange={handleKeywordInput} />
        <AddBtn onClick={handleItemAdd}>
          <AddIcon>
            <img alt="close" src="/icons/ic-add.svg" />
          </AddIcon>
        </AddBtn>
        <InputLimitText>{`${inputKeyword.length} / 20`}</InputLimitText>
      </Input>
      <InfiniteScrollContainer>
        <ItemBox>
          {filteredData &&
            filteredData.map((type, idx) => {
              const { id, name } = type;
              return (
                <Item key={`item-${idx}`} isActive={targetOperationId === id} onClick={() => handleItemClick(id, name)}>
                  {name}
                </Item>
              );
            })}
        </ItemBox>
        <InfiniteScrollTarget ref={ref} />
      </InfiniteScrollContainer>
      <BtnWrap>
        <DeleteBtn onClick={handleDeleteBtnClick}>삭제</DeleteBtn>
        <ConfirmBtn onClick={handleConfirmBtnClick}>확인</ConfirmBtn>
      </BtnWrap>
    </Container>
  );
}

const Container = styled.div`
  ${flex('', '', 'column')}
`;

const Input = styled.div`
  background: #ffffff;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  height: 64px;
  margin: 30px 0 24px 0;
  padding: 14px 53px 14px 13px;
  position: relative;
`;

const InputField = styled.textarea`
  border: none;
  ${font(12, 400, 18)}
  width: 100%;
  height: 100%;
  word-wrap: break-word;
  word-break: break-word;
  font-family: NanumSquare;

  ::placeholder {
    color: #999999;
    ${font(12, 400, 18)}
  }
`;

const AddBtn = styled.button`
  ${flex('center', 'center')}
  width: 16px;
  height: 16px;
  position: absolute;
  right: 13px;
  top: 13px;
`;

const AddIcon = styled.div`
  ${flex('', 'center')}
  ${fitImg};
`;

const InputLimitText = styled.div`
  ${font(12, 400, 18)}
  color : #999999;
  position: absolute;
  bottom: 11px;
  right: 13px;
`;

const InfiniteScrollContainer = styled.div`
  height: 202px;
  margin-bottom: 30px;
  overflow-y: auto;
`;

const InfiniteScrollTarget = styled.div`
  height: 1px;
`;

const ItemBox = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
  ::-webkit-scrollbar {
    display: none;
    width: 0;
  }
  height: fit-content;
`;

const Item = styled.div<{ isActive?: boolean }>`
  background: ${({ isActive }) => (isActive ? '#aaecff' : '#DDDDDD')};
  border-radius: 4px;
  ${font(12, 400, 14)};
  ${flex('center', 'center')};
  color: #000000;
  height: 32px;
  padding: 0 12px;
`;

const BtnWrap = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;
`;

const ConfirmBtn = styled.button`
  ${font(18, 500, 17)}
  ${flex('center', 'center')}
  height: 52px;
  flex: 1;
  border-radius: 5px;
  text-align: center;
  background: #15aedb;
  color: #ffffff;
`;

const DeleteBtn = styled(ConfirmBtn)`
  background-color: #ea3313;
`;
